import { PropsWithChildren } from 'react';
import cn from 'classnames';

import st from './Box.module.less';

interface IProps {
  className?: string;
}

export const Box = ({ className, children }: PropsWithChildren<IProps>) => (
  <div className={cn(st.box, className)}>
    {children}
  </div>
);
