import { SyntheticEvent, useCallback, useState } from 'react';
import { parseCookies } from 'nookies';

import { getAccessToken } from 'api';
import { useAuthStore, useGameDemoStore, useGameStore } from 'store';

import { useCookies } from '../common';

const INITIAL_STATE = {
  login: '',
  password: '',
};

export const useLogin = () => {
  const credentials = useAuthStore.use.credentials();

  const { resetGame } = useGameStore.getState();
  const { resetDemoGame } = useGameDemoStore.getState();
  const { setAuthToken, setAuthorized, setCredentials } = useAuthStore.getState();

  const { setLotopolyCookie } = useCookies();

  const [errors, setErrors] = useState(INITIAL_STATE);

  const { ACCESS_TOKEN, REFRESH_TOKEN } = parseCookies();

  const { login, password } = credentials;

  const handleLogin = useCallback(({ onSuccess, onError, onUnknownError }: {
    onSuccess?: () => void;
    onError?: () => void;
    onUnknownError?: () => void;
  } = {}) => {
    resetGame();
    resetDemoGame();

    getAccessToken(credentials)
      .then(({ accessToken, refreshToken }) => {
        if (accessToken && accessToken !== ACCESS_TOKEN) {
          setLotopolyCookie('ACCESS_TOKEN', accessToken, 172_800_000);
        }

        if (refreshToken && refreshToken !== REFRESH_TOKEN) {
          setLotopolyCookie('REFRESH_TOKEN', refreshToken, 5_184_000_000);
        }

        setAuthorized(true);
        setAuthToken(accessToken);
        onSuccess?.();
      })
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            login: loginError = [],
            password: passwordError = [],
          } = {},
        } = data;

        onError?.();

        if (loginError.length) {
          setErrors({ ...errors, login: loginError[0] });
          return false;
        }

        if (passwordError.length) {
          setErrors({ ...errors, password: passwordError[0] });
          return false;
        }

        onUnknownError?.();
      });
  }, [
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    credentials,
    errors,
    resetGame,
    resetDemoGame,
    setAuthorized,
    setAuthToken,
    setLotopolyCookie,
  ]);

  const handleChange = useCallback(({ currentTarget: { name, value } }: SyntheticEvent<HTMLInputElement>) => {
    setErrors(INITIAL_STATE);
    setCredentials({ ...credentials, [name]: value });
  }, [credentials, setCredentials]);

  return {
    submitDisabled: !login || !password,
    errors,
    login,
    password,
    handleChange,
    handleLogin,
  };
};
