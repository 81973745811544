import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createSelectors } from 'lib/common';

interface IGameStore {
  gameNumber: number | null;
  setGameNumber: (gameNumber: number) => void;

  checkStringHash: string;
  setCheckStringHash: (checkStringHash: string) => void;

  gameId: string;
  setGameId: (gameId: string) => void;

  selectedNumbers: number[];
  setSelectedNumbers: (number: number) => void;
  updateSelectedNumbers: (selectedNumbers: number[]) => void;

  resetGame: () => void;
}

const useGameBase = create<IGameStore>()(
  immer((set) => ({
    gameNumber: null,
    setGameNumber: (gameNumber: number) => set((state) => ({ ...state, gameNumber })),

    checkStringHash: '',
    setCheckStringHash: (checkStringHash: string) => set((state) => ({ ...state, checkStringHash })),

    gameId: '',
    setGameId: (gameId: string) => set((state) => ({ ...state, gameId })),

    selectedNumbers: [],
    setSelectedNumbers: (number) => set(({ selectedNumbers }) => {
      const prevNumIndex = selectedNumbers.indexOf(number);

      if (prevNumIndex > -1) {
        selectedNumbers.splice(prevNumIndex, 1);
      } else {
        selectedNumbers.push(number);
      }
    }),

    updateSelectedNumbers: (selectedNumbers) => set((state) => ({
      ...state,
      selectedNumbers,
    })),

    resetGame: () => set((state) => ({
      ...state,
      gameId: '',
      gameNumber: null,
      checkStringHash: '',
      selectedNumbers: [],
    })),
  })),
);

export const useGameStore = createSelectors(useGameBase);
