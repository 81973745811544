import { PropsWithChildren } from 'react';
import cn from 'classnames';

import st from './Wrap.module.less';

interface IProps {
  as?: 'div' | 'section';
  className?: string;
  id?: string;
}

export const Wrap = ({ as = 'div', className, children, id }: PropsWithChildren<IProps>) => {
  const Component = as;

  return (
    <Component className={cn(st.wrap, className)} id={id}>
      {children}
    </Component>
  );
};
