import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ROUTES } from 'lib/constants';
import { useDialogsAdd } from 'lib/contexts';
import { useAuthStore, useSiteConfigStore } from 'store';
import { useCookies, useDemoGame, useGame } from 'lib/hooks';

import { Button, LoginDialog, Logo, Text } from 'components';

import { Dialog } from '../Dialog';

import st from './DemoWinDialog.module.less';

export const DemoWinDialog = () => {
  const isAuth = useAuthStore.use.isAuth();
  const isMobile = useSiteConfigStore.use.isMobile();

  const { setLotopolyCookie } = useCookies();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const addDialog = useDialogsAdd();
  const { isDemo, isWin, handleRefreshDemoGame } = useGame();
  const { isDemoGamesPlayed } = useDemoGame();

  const [isShown, setShow] = useState(!isAuth && isDemoGamesPlayed);

  useEffect(() => {
    if (isDemo && isWin) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [addDialog, isDemo, isWin, setLotopolyCookie]);

  const handlePlayClick = useCallback(() => {
    setShow(false);

    if (isMobile) {
      addDialog(<LoginDialog />);
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, [addDialog, isMobile, navigate]);

  const handleContinueClick = useCallback(() => {
    handleRefreshDemoGame();
    setShow(false);
  }, [handleRefreshDemoGame]);

  if (!isShown) {
    return null;
  }

  return (
    <Dialog
      mobileAs="modal"
      modalProps={{
        withCloseButton: false,
        withEscapePress: false,
        withOutsideClick: false,
      }}
    >
      <div className={cn(st.demoWinDialog)}>
        <div className={st.content}>
          <Logo small className={st.logo} />

          <Text uppercase className={st.title} size="xl" weight="w700">
            {t('win_demo_game_dialog.wow')}
          </Text>

          <Text size="l" weight="w500">
            {t('win_demo_game_dialog.great')}
            <br />
            {t('win_demo_game_dialog.try_playing')}
          </Text>
        </div>

        <div className={st.buttons}>
          <Button onClick={handlePlayClick}>
            {t('start_game_dialog.buttons.login')}
          </Button>

          {!isDemoGamesPlayed && (
            <Button theme="secondary" onClick={handleContinueClick}>
              {t('win_demo_game_dialog.continue_btn')}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
