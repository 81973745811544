import cn from 'classnames';
import { Trans } from 'react-i18next';

import { Text } from 'components';

import st from './Instruction.module.less';

interface IProps {
  className?: string;
  title: string;
  items: string[];
}

export const Instruction = ({ className, title, items }: IProps) => (
  <div className={cn(st.instruction, className)}>
    <Text className={cn(st.title, st.text)} size="s" weight="w600">
      {title}
    </Text>

    <ol className={st.list}>
      {items.map((item, index) => (
        <Text className={st.text} as="li" size="xs" key={index}>
          {item}
        </Text>
      ))}
    </ol>

    <Text className={st.text} color="grayBlue2" size="xs" weight="w700">
      <Trans i18nKey="cashier_page.credit_amount.instruction.help" />
    </Text>
  </div>
);
