import { useCallback } from 'react';
import cn from 'classnames';

import { useGame } from 'lib/hooks';
import { MAX_GAME_CHIPS } from 'lib/constants';

import st from './GameButton.module.less';

interface IProps {
  className?: string;
  value?: number;
}

export const GameButton = ({ className, value }: IProps) => {
  const {
    isWin,
    status,
    selectedNumbers,
    winNumber,
    setSelectedNumbers,
  } = useGame();

  const handleClick = useCallback((number: number) => () => setSelectedNumbers(number), [setSelectedNumbers]);

  if (!value) {
    return null;
  }

  const isActive = selectedNumbers.includes(value);

  return (
    <button
      type="button"
      disabled={!!status || (!isActive && selectedNumbers.length >= MAX_GAME_CHIPS)}
      className={
        cn(
          st.gameButton,
          {
            [st.win]: isWin && winNumber === value,
            [st.loss]: !isWin && winNumber === value,
            [st.active]: isActive,
          },
          className,
        )
      }
      onClick={handleClick(value)}
    >
      {value}
    </button>
  );
};
