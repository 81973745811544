import { useState, TouchEventHandler, useCallback, useEffect, useMemo } from 'react';

const DISTANCE_TO_CLOSE = 30;
const DISTANCE_TO_MOVE = 10;

export const useSlipOffTouches = ({ isActive, onClose }: { isActive?: boolean; onClose?: () => void }) => {
  const [startY, setStartY] = useState<null | number>(null);
  const [stopY, setStopY] = useState<null | number>(null);
  const [currentY, setCurrentY] = useState<null | number>(null);

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (isActive && e.touches[0].clientY) {
        setStartY(e.touches[0].clientY);
      }
    },
    [isActive],
  );

  const handleTouchStop: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (isActive && e.changedTouches[0].clientY) {
        setStopY(e.changedTouches[0].clientY);
      }
    },
    [isActive],
  );

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (isActive && e.changedTouches[0].clientY) {
        setCurrentY(e.changedTouches[0].clientY);
      }
    },
    [isActive],
  );

  const deltaY = useMemo(
    () => currentY && startY && currentY - startY > DISTANCE_TO_MOVE && currentY - startY,
    [currentY, startY],
  );

  useEffect(() => {
    if (!(isActive && startY && stopY)) {
      return;
    }

    if (stopY - startY >= DISTANCE_TO_CLOSE) {
      onClose?.();
    }

    setStartY(null);
    setStopY(null);
    setCurrentY(null);
  }, [isActive, onClose, startY, stopY]);

  return {
    handleStart: handleTouchStart,
    handleStop: handleTouchStop,
    handleMove: handleTouchMove,
    deltaY,
  };
};
