import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { ITextProps } from 'lib/types';

import { Text } from 'components';

import st from './TableCell.module.less';

interface IProps extends ITextProps {
  type?: 'td' | 'th';
  className?: string;
}

export const TableCell = ({ type = 'td', className, children, ...textProps }: PropsWithChildren<IProps>) => {
  if (type === 'th') {
    return (
      <Text as="div" className={cn(st.tableTh, className)} size="s" {...textProps}>
        {children}
      </Text>
    );
  }

  return (
    <Text as="div" className={cn(st.tableTd, className)} weight="w500" {...textProps}>
      {children}
    </Text>
  );
};
