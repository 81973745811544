import { PropsWithChildren, useEffect, useMemo } from 'react';
import { createSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getUrl, searchParams } from 'lib/utils';
import { ROUTES } from 'lib/constants';
import { ECashierTabs } from 'lib/types';

import { useView } from '../hooks';
import { CONFIG } from '../config';

import st from './Tabs.module.less';

interface IProps {
  className?: string;
}

export const Tabs = ({ className }: PropsWithChildren<IProps>) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  const fromGame = searchParams.get(search, 'fromGame') || '';

  const view = useView();

  useEffect(() => {
    if (!search || !Object.values(ECashierTabs).includes(view)) {
      navigate({
        pathname: ROUTES.CASHIER,
        search: createSearchParams({
          ...fromGame && { fromGame },
          view: 'deposit',
        }).toString(),
      });
    }
  }, [fromGame, navigate, search, view]);

  const content = useMemo(() => Object.entries(CONFIG).map(([slug, { title }]) => (
    <Link
      className={cn(st.tab, { [st.active]: view === slug })}
      to={getUrl(ROUTES.CASHIER, { query: { view: slug } })}
      key={slug}
      type="button"
    >
      {t(title)}
    </Link>
  )), [view, t]);

  return (
    <div className={cn(st.tabs, className)}>
      {content}
    </div>
  );
};
