import { PropsWithChildren } from 'react';
import { parseCookies } from 'nookies';

import { useAuthStore } from 'store';
import { DialogsProvider } from 'lib/providers';

import { SiteConfigurations, Topline, Wrap } from 'components';

import st from './Main.module.less';

export const Main = ({ children }: PropsWithChildren) => {
  const isAuth = useAuthStore.use.isAuth();

  const { setAuthToken, setAuthorized } = useAuthStore.getState();

  const { ACCESS_TOKEN } = parseCookies();

  if (!isAuth && ACCESS_TOKEN) {
    setAuthorized(true);
    setAuthToken(ACCESS_TOKEN);
  }

  return (
    <>
      <SiteConfigurations />

      <DialogsProvider>
        <Topline />

        <main className={st.main}>
          <Wrap>
            {children}
          </Wrap>
        </main>
      </DialogsProvider>
    </>
  );
};
