import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getUrl } from 'lib/utils';
import { useGame } from 'lib/hooks';
import { ROUTES } from 'lib/constants';
import { useNewGameQuery } from 'api';
import { ECashierTabs } from 'lib/types';
import { useDialogClose } from 'lib/contexts';

import { Button, Dialog, Text, ValueRow } from 'components';

import st from './WinDialog.module.less';

export const WinDialog = () => {
  const { t } = useTranslation();
  const closeDialog = useDialogClose();

  const { checkString, winNumberString, resetGame } = useGame();

  const { refetch: startNewGame } = useNewGameQuery({
    enabled: false,
  });

  const handlePlayAgain = useCallback(() => {
    resetGame();
    startNewGame();
  }, [resetGame, startNewGame]);

  return (
    <Dialog>
      <Text className={st.title} size="xl" weight="w600">
        {t('game.win_dialog')}
      </Text>

      <ValueRow label={t('game.hidden_number')}>
        {winNumberString}
      </ValueRow>

      <ValueRow label={t('game.check_line')}>
        {checkString || ''}
      </ValueRow>

      <div className={st.buttons} onClick={closeDialog}>
        <Button onClick={handlePlayAgain}>
          {t('game.play_again_btn')}
        </Button>

        <Button theme="secondaryModal" href={getUrl(ROUTES.CASHIER, { query: { view: ECashierTabs.WITHDRAW } })}>
          {t('game.withdraw_btn')}
        </Button>
      </div>
    </Dialog>
  );
};
