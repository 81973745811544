import { useQuery } from 'react-query';

import { api, getUrl } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { ITransactionExternalDTOGetAllDTO } from '../../contracts';

interface IAccountTransactionsExternal {
  page: number;
  pageSize: number;
}

const fetchAccountTransactionsExternal = ({
  page = 1,
  pageSize = 100,
}: IAccountTransactionsExternal): Promise<ITransactionExternalDTOGetAllDTO> => (
  api.get(getUrl('/api/account/transactions/external', { query: { page: String(page), pageSize: String(pageSize) } }))
);

type TQueryFn = typeof fetchAccountTransactionsExternal;

export const useAccountTransactionsExternalQuery = <D = ExtractFnReturnType<TQueryFn>>(
  search: IAccountTransactionsExternal,
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['account-transactions-external', search],
  queryFn: () => fetchAccountTransactionsExternal(search),
});
