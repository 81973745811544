import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { Text, Tooltip } from 'components';

import st from './InfoBox.module.less';

interface IProps {
  className?: string;
  label: string;
  tooltip?: string;
}

export const InfoBox = ({ className, children, label, tooltip }: PropsWithChildren<IProps>) => (
  <Text as="div" className={cn(st.infoBox, className)} size="s" weight="w600">
    {label}

    {tooltip && (
      <Tooltip className={st.tooltip}>
        {tooltip}
      </Tooltip>
    )}

    <Text as="div" color="white" className={st.content} size="base">
      {children}
    </Text>
  </Text>
);
