import { useCallback } from 'react';
import { destroyCookie, setCookie } from 'nookies';

export const useCookies = () => {
  const oneDay = 24 * 60 * 60;

  const setLotopolyCookie = useCallback((
    name: string,
    value: string | number | boolean | null,
    maxAge: number | null = oneDay,
  ) => {
    setCookie(null, name, String(value), {
      maxAge,
      path: '/',
      encode: (arg: string | number | boolean) => String(arg),
    });
  }, [oneDay]);

  const removeLotopolyCookie = useCallback((name: string) => {
    destroyCookie(null, name, { path: '/' });
  }, []);

  return { setLotopolyCookie, removeLotopolyCookie };
};
