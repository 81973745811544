import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDialogsAdd } from 'lib/contexts';
import { numberToMoney } from 'lib/utils';

import { Button, DialogPayment, ResultDialog } from 'components';

import { usePlayButton } from './usePlayButton';

interface IProps {
  className?: string;
}

const PlayButton = ({ className }: IProps) => {
  const { t } = useTranslation();

  const {
    isDemo,
    isEnoughMoney,
    isLoading,
    addFunds,
    selectedNumbersCount,
    status,
    playDemoGame,
    handlePlay,
    handleRefreshGame,
    handleRefreshDemoGame,
  } = usePlayButton();

  const addDialog = useDialogsAdd();

  const showPlayErrorDialog = useCallback(() => addDialog(
    <ResultDialog error title={<Trans i18nKey="error_message" />} />,
  ), [addDialog]);

  const handleAddClick = useCallback(() => addDialog(<DialogPayment />), [addDialog]);

  if (isDemo && !status) {
    return (
      <Button
        fluid
        disabled={!selectedNumbersCount}
        className={className}
        onClick={playDemoGame}
      >
        {t('game.play_btn')}
      </Button>
    );
  }

  if (isDemo && status) {
    return (
      <Button fluid className={className} onClick={handleRefreshDemoGame}>
        {t('game.play_again_btn')}
      </Button>
    );
  }

  if (!status && !isEnoughMoney && !!selectedNumbersCount) {
    return (
      <Button
        fluid
        theme="action"
        loading={isLoading}
        className={className}
        onClick={handleAddClick}
      >
        {t('game.add_funds_btn', { value: numberToMoney(addFunds) })}
      </Button>
    );
  }

  if (status) {
    return (
      <Button fluid className={className} onClick={handleRefreshGame}>
        {t('game.play_again_btn')}
      </Button>
    );
  }

  return (
    <Button
      fluid
      loading={isLoading}
      disabled={!selectedNumbersCount}
      className={className}
      onClick={handlePlay({ onError: showPlayErrorDialog })}
    >
      {t(`game.${selectedNumbersCount ? 'play' : 'select'}_btn`)}
    </Button>
  );
};

export default PlayButton;
