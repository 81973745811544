import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ROUTES } from 'lib/constants';
import { useSiteConfigStore } from 'store';
import { useDialogClose, useDialogsAdd } from 'lib/contexts';

import { Button, Dialog, LanguageSwitcher, LoginDialog, Logo, Text, Title } from 'components';

import st from './StartGameDialog.module.less';

export const StartGameDialog = () => {
  const isDesktop = useSiteConfigStore.use.isDesktop();

  const { t } = useTranslation();

  const addDialog = useDialogsAdd();
  const closeDialog = useDialogClose();

  const modalProps = {
    withCloseButton: false,
    withEscapePress: false,
    withOutsideClick: false,
  };

  const handleLogin = useCallback(() => {
    closeDialog();
    addDialog(<LoginDialog />);
  }, [addDialog, closeDialog]);

  const handlePlayDemo = useCallback(() => closeDialog(), [closeDialog]);

  return (
    <Dialog modalProps={modalProps} mobileAs="modal">
      <div className={st.startGameDialog}>
        <div className={st.content}>
          <Logo small className={st.logo} />

          <Title as="p" className={st.title} size="h2">
            <Trans i18nKey="start_game_dialog.title" />
          </Title>
        </div>

        <Text color="grayBlue">
          {t('start_game_dialog.description')}
        </Text>

        <div className={st.buttons}>
          {isDesktop ? (
            <Button href={ROUTES.LOGIN}>
              {t('start_game_dialog.buttons.login')}
            </Button>
          ) : (
            <Button onClick={handleLogin}>
              {t('start_game_dialog.buttons.login')}
            </Button>
          )}

          <Button fluid theme="secondary" onClick={handlePlayDemo}>
            {t('start_game_dialog.buttons.play_demo')}
          </Button>

          <LanguageSwitcher className={st.languageSwitcher} position="top-center" />
        </div>
      </div>
    </Dialog>
  );
};
