import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { useLogin } from 'lib/hooks';
import { ROUTES } from 'lib/constants';
import { useDialogsAdd } from 'lib/contexts';

import {
  FormButtons,
  InnerPage,
  Input,
  InputPassword,
  ResultDialog,
  Text,
} from 'components';

import { AuthTabs } from '../Tabs';
import { AuthWarning } from '../Warning';

import st from './Login.module.less';

export const Login = () => {
  const { t } = useTranslation();

  const {
    submitDisabled,
    errors,
    login,
    password,
    handleChange,
    handleLogin,
  } = useLogin();

  const addDialog = useDialogsAdd();

  const handleLoginClick = useCallback(() => {
    handleLogin({
      onSuccess: () => addDialog(<ResultDialog title={t('login_page.success')} href={ROUTES.GAME} />),
      onUnknownError: () => addDialog(
        <ResultDialog error title={<Trans i18nKey="login_page.error" />} />,
      ),
    });
  }, [addDialog, handleLogin, t]);

  return (
    <InnerPage backLink={ROUTES.GAME}>
      <AuthTabs className={st.authTabs} />

      <AuthWarning />

      <form className={st.form}>
        <Input
          errorMessage={errors.login}
          autoComplete="username"
          name="login"
          placeholder={t('login')}
          value={login}
          onChange={handleChange}
        />

        <InputPassword
          errorMessage={errors.password}
          autoComplete="current-password"
          name="password"
          placeholder={t('password')}
          value={password}
          onChange={handleChange}
        />

        <Text as="div" className={st.recoveryRow} weight="w500" size="s">
          <Link className={st.recoveryLink} to={ROUTES.RECOVERY}>
            {t('login_page.forgot_password')}
          </Link>
        </Text>
      </form>

      <FormButtons
        buttonText={t('login_page.login_btn')}
        submitDisabled={submitDisabled}
        cancelHref={ROUTES.GAME}
        onSubmit={handleLoginClick}
      />
    </InnerPage>
  );
};
