import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconClock } from 'assets';

import { Text } from '../Text';
import { Icon } from '../Icon';

import st from './Timer.module.less';

interface IProps {
  className?: string;
}

export const Timer = ({ className }: IProps) => {
  const { t } = useTranslation();

  return (
    <Text color="grayBlue" className={cn(st.timer, className)} size="s">
      {t('cashier_page.payment_details.waiting')}
      <Text as="span" className={st.time} color="white" weight="w600">
        {t('cashier_page.payment_details.hours')}
        <Icon className={st.iconClock} icon={iconClock} size={16} />
      </Text>
    </Text>
  );
};
