import { Button, Dialog, Logo, Text, Title } from 'components';

import st from './RulesDialog.module.less';
import { img1, img2, img3, img4 } from './img';
import { useDialogClose } from '../../../lib/contexts';

export const RulesDialog = () => {
  const closeDialog = useDialogClose();

  return (
    <Dialog mobileAs="modal" modalProps={{ className: st.modal, closeBtnClassName: st.closeBtn }}>
      <div className={st.rulesDialog}>
        <Logo small className={st.logo} />

        <Title as="p" className={st.title} size="h2">
          Rules and Mechanics Details
        </Title>

        <div className={st.section}>
          <Text className={st.mainText} color="grayBlue">
            Lotopoly&nbsp;&mdash; is&nbsp;a&nbsp;game with transparent mechanics and a&nbsp;predetermined outcome,
            secured
            by&nbsp;the complex md5 encryption algorithm.
          </Text>
        </div>

        <div className={st.section}>
          <Text className={st.subtitle} weight="w700">
            How to&nbsp;Play?
          </Text>

          <p className={st.text}>
            To&nbsp;play roulette, you must have enough funds in&nbsp;your account to&nbsp;purchase at&nbsp;least one
            chip. Each chip costs&nbsp;$1, and the maximum number of&nbsp;chips is&nbsp;100. The more chips you have,
            the
            higher your chances of&nbsp;winning.&nbsp;1&nbsp;chip equals a&nbsp;1% chance of&nbsp;winning; 10&nbsp;chips
            equal a&nbsp;10% chance; 53&nbsp;chips equal a&nbsp;53% chance, and so&nbsp;on.
            For example, we&nbsp;chose 50&nbsp;chips, which gives&nbsp;us a&nbsp;50% chance of&nbsp;winning (the same
            odds
            as&nbsp;flipping a&nbsp;coin).
          </p>

          <img className={st.img} src={img1} alt="" />

          <p className={st.text}>
            In&nbsp;a&nbsp;10&times;10&nbsp;grid, you can choose any numbers from 1&nbsp;to&nbsp;100, matching the
            number
            of&nbsp;your chips (1&nbsp;chip = 1&nbsp;number; 10&nbsp;chips = 10&nbsp;numbers, etc.). Feel free
            to&nbsp;choose any numbers. If&nbsp;you have any lucky numbers or&nbsp;lucky combinations, please use them,
            there are no&nbsp;restrictions.
          </p>

          <p className={st.text}>
            If&nbsp;the winning number falls within the cells you have chosen, you win $100. The winning amount
            is&nbsp;automatically credited to&nbsp;your account. You can always check your draw in&nbsp;your personal
            account or&nbsp;in&nbsp;the game history.
          </p>

          <img className={st.img} src={img2} alt="" />
        </div>

        <div className={st.section}>
          <Text className={st.subtitle} weight="w700">
            Is&nbsp;Roulette a&nbsp;Scam?
          </Text>

          <p className={st.text}>
            Of&nbsp;course not. Lotopoly is&nbsp;not a&nbsp;casino, so&nbsp;the principle &laquo;the house always
            wins&raquo; does not apply here. Even more&nbsp;so, the winning number is&nbsp;predetermined and written
            next
            to&nbsp;the 10&times;10 field before the game begins. Pay attention to&nbsp;the &laquo;Roulette
            Hash&raquo; line; the winning number is&nbsp;already encrypted in&nbsp;this combination.
            It&nbsp;is&nbsp;known
            to&nbsp;the system in&nbsp;advance, so&nbsp;the winning number cannot change based on&nbsp;the numbers you
            chose or&nbsp;any other factors.
          </p>

          <img className={st.img} src={img3} alt="" />
        </div>

        <div className={st.section}>
          <Text className={st.subtitle} weight="w700">
            What is&nbsp;Encrypted in&nbsp;the &laquo;Roulette Hash&raquo;?
          </Text>

          <p className={st.text}>
            Here, in&nbsp;advance, through the complex md5 encryption algorithm, encrypted are: the roulette number
            (16512101), the winning number (64), and an&nbsp;additional coefficient (a&nbsp;long combination
            of&nbsp;18-20&nbsp;digits, it&nbsp;has no&nbsp;significance for the winning number and is&nbsp;separated
            from
            the winning number by&nbsp;a&nbsp;dot; it&nbsp;exists to&nbsp;further secure the &laquo;Roulette
            Hash&raquo; against hacking). After the roulette results are obtained, the system shows you that these
            3&nbsp;components (roulette number, winning number, and additional coefficient) make
            up&nbsp;the &laquo;Roulette Hash&raquo; that was visible at&nbsp;the start.
          </p>

          <img className={st.img} src={img4} alt="" />

          <p className={st.text}>
            To&nbsp;verify the Hash, search &laquo;create md5&nbsp;hash&raquo; in&nbsp;any search engine, and dozens
            of&nbsp;sites will appear where you can encode any combination using md5. Just enter the combination from
            the
            winning results, and you&rsquo;ll get the roulette hash again. It&rsquo;s all fair!
          </p>

          <p className={st.text}>
            The more chips you have, the higher your chances of&nbsp;winning&nbsp;&mdash; it&rsquo;s very simple and
            straightforward.
          </p>
        </div>

        <Button className={st.btn} onClick={closeDialog}>
          Go to game!
        </Button>
      </div>
    </Dialog>
  );
};
