import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { numberToMoney } from 'lib/utils';
import { useDialogsAdd } from 'lib/contexts';
import { MIN_DEPOSIT_AMOUNT } from 'lib/constants';
import { Button, Input, ResultDialog, Spinner, Text, Instruction } from 'components';

import MinAmount from '../../MinAmount';
import { CurrencyDropdown } from '../../CurrencyDropdown';

import { useCreditAmount } from './useCreditAmount';

import st from './CreditAmount.module.less';

interface IProps {
  className?: string;
}

export const CreditAmount = ({ className }: IProps) => {
  const { t } = useTranslation();

  const addDialog = useDialogsAdd();

  const {
    isLoading,
    animated,
    amountUsd,
    error,
    fetchDepositCrypto,
    handleChange,
  } = useCreditAmount();

  const handleContinueClick = useCallback(() => {
    fetchDepositCrypto()
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            amountUsd: amountUsdErrors = [],
            value: valueErrors = [],
          } = {},
        } = data;

        if (amountUsdErrors.length) {
          addDialog(
            <ResultDialog error title={amountUsdErrors[0]} />,
          );

          return false;
        }

        if (valueErrors.length) {
          addDialog(
            <ResultDialog error title={valueErrors[0]} />,
          );

          return false;
        }
      });
  }, [addDialog, fetchDepositCrypto]);

  return (
    <div className={cn(st.creditAmount, className)}>
      <Instruction
        title={t('cashier_page.credit_amount.instruction.title')}
        className={st.instruction}
        items={[
          t('cashier_page.credit_amount.instruction.step_1'),
          t('cashier_page.credit_amount.instruction.step_2'),
          t('cashier_page.credit_amount.instruction.step_3'),
        ]}
      />

      <CurrencyDropdown className={st.currencyDropdown} />

      <Text className={st.text} weight="w500" mobileSize="s">
        {t('cashier_page.credit_amount.description')}
        &nbsp;
        <MinAmount animated={animated}>
          {numberToMoney(MIN_DEPOSIT_AMOUNT)}
        </MinAmount>
        .
      </Text>

      <Input
        containerClassName={st.field}
        errorMessage={error}
        value={amountUsd ? `$${String(amountUsd)}` : ''}
        name="amountUsd"
        placeholder={t('cashier_page.credit_amount.placeholder')}
        type="text"
        onChange={handleChange}
      />

      <Button
        fluid
        disabled={Number(amountUsd) < MIN_DEPOSIT_AMOUNT}
        className={st.btn}
        onClick={handleContinueClick}
      >
        {t('continue')}
      </Button>

      <Spinner isLoading={isLoading} absolute withBackdropBlur className={st.spinner} />
    </div>
  );
};
