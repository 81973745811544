import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { iconSpinner, iconSpinnerBtn } from 'assets';

import { Icon } from 'components';

import st from './Spinner.module.less';

interface IProps {
  isLoading?: boolean;
  centered?: boolean;
  className?: string;
  spinnerClassName?: string;
  size?: number;
  theme?: 'primary' | 'btn';
  absolute?: boolean;
  withBackdropBlur?: boolean;
}

export const Spinner = ({
  isLoading = true,
  absolute,
  centered,
  className,
  spinnerClassName,
  size = 24,
  theme = 'primary',
  withBackdropBlur,
  children,
}: PropsWithChildren<IProps>) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={cn(
        st.container,
        [st[theme]],
        {
          [st.centered]: centered,
          [st.absolute]: absolute,
          [st.blur]: withBackdropBlur,
        },
        className,
      )}
    >
      <Icon
        className={cn(st.spinner, spinnerClassName)}
        icon={theme === 'btn' ? iconSpinnerBtn : iconSpinner}
        size={size}
      />

      {children}
    </div>
  );
};
