import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { iconCopy } from 'assets';
import { registerUser } from 'api';
import { useLogin, useRegistration } from 'lib/hooks';
import { useDialogClose, useDialogsAdd } from 'lib/contexts';

import {
  Button,
  CopiedSuccess,
  Icon,
  Input,
  InputPassword,
  LoginDialog,
  PseudoLink,
  ResultDialog,
  Text,
} from 'components';

import { Dialog } from '../Dialog';

import st from './RegistrationDialog.module.less';
import { ELanguages } from '../../../lib/types';

export const RegistrationDialog = () => {
  const {
    isCopied,
    submitDisabled,
    errors,
    login,
    name,
    passphrase,
    password,
    setErrors,
    handleChange,
    handleCopy,
    handleRefreshPhrase,
  } = useRegistration();

  const { t, i18n: { resolvedLanguage } } = useTranslation();
  const addDialog = useDialogsAdd();
  const closeDialog = useDialogClose();

  const { handleLogin } = useLogin();

  const handleRegister = useCallback(() => {
    registerUser({
      languageCode: resolvedLanguage || ELanguages.EN,
      login,
      name,
      passphrase,
      password,
    })
      .then(() => {
        closeDialog();
        addDialog(<ResultDialog title={t('registration_page.success')} onClick={handleLogin} />);
      })
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            login: loginErrors = [],
            name: nameErrors = [],
            password: passwordErrors = [],
          } = {},
        } = data;

        if (loginErrors.length) {
          setErrors({
            ...errors,
            login: loginErrors[0],
          });
        }

        if (nameErrors.length) {
          setErrors({
            ...errors,
            name: nameErrors[0],
          });
        }

        if (passwordErrors.length) {
          setErrors({
            ...errors,
            password: passwordErrors[0],
          });
        }

        if (loginErrors.length || nameErrors.length || passwordErrors.length) {
          return false;
        }

        closeDialog();

        addDialog(
          <ResultDialog
            error
            title={<Trans i18nKey="registration_page.error" />}
            onClick={() => addDialog(<RegistrationDialog />)}
          />,
        );
      });
  }, [resolvedLanguage, login, name, passphrase, password, closeDialog, addDialog, t, handleLogin, setErrors, errors]);

  const handleCancelClick = useCallback(() => {
    closeDialog();
    addDialog(<LoginDialog />);
  }, [addDialog, closeDialog]);

  const handleClose = useCallback(() => addDialog(<LoginDialog />), [addDialog]);

  return (
    <Dialog onClose={handleClose}>
      <Text className={st.title} size="xl" weight="w600">
        {t('registration')}
      </Text>

      <form className={st.form}>
        <div className={st.row}>
          <Input
            modal
            autoComplete="username"
            errorMessage={errors.login}
            name="login"
            placeholder={t('login')}
            value={login}
            onChange={handleChange}
          />

          <Input
            modal
            autoComplete="given-name"
            errorMessage={errors.name}
            name="name"
            placeholder={t('registration_page.your_name')}
            value={name}
            onChange={handleChange}
          />

          <InputPassword
            modal
            autoComplete="new-password"
            errorMessage={errors.password}
            name="password"
            placeholder={t('password')}
            value={password}
            onChange={handleChange}
          />
        </div>

        <div className={st.row}>
          <div>
            <Text className={st.description} weight="w600">
              {t('registration_page.secret_word')}
            </Text>

            <Text className={st.text}>
              <Trans i18nKey="registration_page.secret_phrase" />
            </Text>
          </div>

          <div className={st.phraseField}>
            <Input modal readOnly className={st.phraseInput} value={passphrase} />

            <button className={st.copyBtn} type="button" onClick={handleCopy}>
              <Icon icon={iconCopy} />
            </button>
          </div>
        </div>
      </form>

      <div className={st.updatePhraseRow}>
        <PseudoLink size="s" color="grayBlue2" onClick={handleRefreshPhrase}>
          {t('registration_page.update_phrase')}
        </PseudoLink>
      </div>

      <div className={st.buttons}>
        <Button fluid disabled={submitDisabled} onClick={handleRegister}>
          {t('registration_page.registration_btn')}
        </Button>

        <Button fluid theme="secondaryModal" onClick={handleCancelClick}>
          {t('cancel')}
        </Button>
      </div>

      <CopiedSuccess isShow={isCopied} color="white" />
    </Dialog>
  );
};
