import { Dispatch, SetStateAction, SyntheticEvent } from 'react';

export const handleFieldChange = (setValues: Dispatch<SetStateAction<any>>) => ({
  currentTarget: {
    name,
    value,
  },
}: SyntheticEvent<HTMLInputElement>) => {
  setValues((values: any) => ({
    ...values,
    [name]: value,
  }));
};
