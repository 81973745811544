import { useEffect } from 'react';

import { useSiteConfigStore } from 'store';
import { useMediaBetween, useMediaGreater, useMediaSmaller } from 'lib/hooks';

export const SiteConfigurations = () => {
  const { setBreakpoints } = useSiteConfigStore.getState();

  const isDesktop = useMediaGreater('mTablet');
  const isTablet = useMediaBetween('sTablet', 'mTablet');
  const isMobile = useMediaSmaller('sTablet');

  useEffect(() => {
    setBreakpoints({ isDesktop, isTablet, isMobile });
  }, [isDesktop, isMobile, isTablet, setBreakpoints]);

  return null;
};
