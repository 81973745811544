import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ROUTES } from 'lib/constants';

import {
  Cashier,
  ChangePassword,
  Error404,
  Game,
  Login,
  PersonalAccount,
  Profile,
  Recovery,
  Registration,
} from 'pages';

import { Main, PrivateRoute, ErrorBoundary } from './components';

import 'styles/styles.less';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
      <Router>
        <Main>
          <Routes>
            <Route path={ROUTES.GAME} element={<Game />} />

            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.RECOVERY} element={<Recovery />} />
            <Route path={ROUTES.REGISTRATION} element={<Registration />} />
            <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />

            <Route path={ROUTES.PROFILE} element={<PrivateRoute component={<Profile />} />} />
            <Route path={ROUTES.CASHIER} element={<PrivateRoute component={<Cashier />} />} />
            <Route path={ROUTES.PERSONAL_ACCOUNT} element={<PrivateRoute component={<PersonalAccount />} />} />

            <Route path="*" element={<Error404 />} />
          </Routes>
        </Main>
      </Router>
    </ErrorBoundary>
  </QueryClientProvider>
);
