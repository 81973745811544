export const ROUTES = {
  GAME: '/',

  CHANGE_PASSWORD: '/change-password',
  LOGIN: '/login',
  RECOVERY: '/recovery',
  REGISTRATION: '/registration',

  CASHIER: '/cashier',
  PERSONAL_ACCOUNT: '/personal-account',
  PROFILE: '/profile',

  ERROR_404: '/404',
};
