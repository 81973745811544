import { useLocation } from 'react-router-dom';

import { ECashierTabs } from 'lib/types';
import { searchParams } from 'lib/utils';

export const useView = () => {
  const { search } = useLocation();

  return searchParams.get(search, 'view') as ECashierTabs;
};
