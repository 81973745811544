import { ROUTES } from 'lib/constants';

import { Button, Title, Wrap } from 'components';

import st from './Error.module.less';

export const Error = () => (
  <Wrap>
    <div className={st.error}>
      <Title className={st.title}>
        Error
      </Title>

      <Button fluid className={st.btn} href={ROUTES.GAME}>
        Go to game
      </Button>
    </div>
  </Wrap>
);
