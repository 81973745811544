import { useEffect } from 'react';

import { useDemoGame } from 'lib/hooks';
import { useNewDemoGameQuery, useNewGameQuery } from 'api';
import { useAuthStore, useGameDemoStore, useGameStore } from 'store';

export const useInitGame = () => {
  const isAuth = useAuthStore.use.isAuth();

  const gameNumber = useGameStore.use.gameNumber();

  const { setGameId, setGameNumber, setCheckStringHash } = useGameStore.getState();

  const {
    setDemo,
    setDemoGameId,
    setDemoWinNumber,
    setDemoCheckString,
    setDemoGameNumber,
    setDemoCheckStringHash,
  } = useGameDemoStore.getState();

  const { isDemoGamesPlayed } = useDemoGame();

  const { data: { id = '', number = 0, checkStringHash = '' } = {} } = useNewGameQuery({
    enabled: isAuth && !gameNumber,
  });

  const {
    data: {
      id: demoId = '',
      number: demoNumber = 0,
      checkString,
      checkStringHash: demoCheckStringHash = '',
      winNumber = 0,
    } = {},
  } = useNewDemoGameQuery({
    enabled: !isAuth && !gameNumber && !isDemoGamesPlayed,
  });

  useEffect(() => {
    if (isAuth) {
      setDemo(false);
      setGameId(id);
      setGameNumber(number);
      setCheckStringHash(checkStringHash);
    } else {
      setDemo(true);
      setDemoGameId(demoId);
      setDemoGameNumber(demoNumber);
      setDemoCheckStringHash(demoCheckStringHash);
      setDemoCheckString(checkString || '');
      setDemoWinNumber(winNumber);
    }
  }, [
    isAuth,
    id,
    number,
    checkString,
    checkStringHash,
    setGameId,
    setGameNumber,
    setCheckStringHash,

    demoId,
    winNumber,
    demoNumber,
    demoCheckStringHash,
    setDemo,
    setDemoGameId,
    setDemoWinNumber,
    setDemoCheckString,
    setDemoGameNumber,
    setDemoCheckStringHash,
  ]);
};
