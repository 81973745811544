import cn from 'classnames';

import { isDev } from 'lib/constants';
import { ButtonFinishDemoGame, LanguageSwitcher, Logo } from 'components';

import st from './Topline.module.less';

interface IProps {
  className?: string;
}

export const Topline = ({ className }: IProps) => (
  <aside className={cn(st.topline, className)}>
    <Logo />

    {isDev && <LanguageSwitcher className={st.devLangSwitcher} />}

    <ButtonFinishDemoGame className={st.buttonFinishDemoGame} />
  </aside>
);
