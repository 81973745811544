import { useQuery } from 'react-query';

import { api } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { IUserDTO } from '../../contracts';

const fetchCurrentUser = (): Promise<IUserDTO> => api.get('/api/users/current');

type TQueryFn = typeof fetchCurrentUser;

export const useCurrentUserQuery = <D = ExtractFnReturnType<TQueryFn>>(
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['current-user'],
  queryFn: fetchCurrentUser,
});
