import { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { iconArrowAccordion } from 'assets';
import { Currency } from 'api';
import { CURRENCIES } from 'lib/models';
import { useToggleOpen } from 'lib/hooks';

import { Icon } from 'components';

import { useCashierStore } from '../store';

import st from './CurrencyDropdown.module.less';

interface IProps {
  className?: string;
}

export const CurrencyDropdown = ({ className }: IProps) => {
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { setCurrentCurrency } = useCashierStore.getState();

  const { isOpen, ref, close: closeDropdown, toggleOpen } = useToggleOpen({ lockBody: false });

  const { icon: currentIcon, text: currentCurrencyText } = CURRENCIES[currentCurrency];

  const handleOptionClick = useCallback((slug: Currency) => () => {
    setCurrentCurrency(slug);
    closeDropdown();
  }, [closeDropdown, setCurrentCurrency]);

  const content = useMemo(() => Object.values(CURRENCIES).map(({ slug, text, icon }) => {
    if (slug === Currency.Usd) {
      return null;
    }

    return (
      <button className={cn(st.btn, st.option)} type="button" key={slug} onClick={handleOptionClick(slug)}>
        {icon && <Icon icon={icon} size={20} />}
        {text}
      </button>
    );
  }), [handleOptionClick]);

  return (
    <div className={cn(st.currencyDropdown, { [st.opened]: isOpen }, className)} ref={ref}>
      <button className={cn(st.btn, st.current)} type="button" onClick={toggleOpen}>
        {currentIcon && <Icon icon={currentIcon} size={20} />}
        {currentCurrencyText}
        <Icon className={st.iconArrowAccordion} icon={iconArrowAccordion} size={20} />
      </button>

      <div className={st.dropdown}>
        {content}
      </div>
    </div>
  );
};
