import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { iconCopy } from 'assets';
import { useDepositCrypto } from 'api';
import { useCopyToClipboard } from 'lib/hooks';
import { CopiedSuccess, Icon, Text } from 'components';

import { useCashierStore } from '../../../store';

import st from './AmountToPay.module.less';

export const AmountToPay = () => {
  const amountUsd = useCashierStore.use.amountUsd();
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { t } = useTranslation();

  const { data } = useDepositCrypto({ amountUsd, currency: currentCurrency }, {
    enabled: false,
  });

  const { amount = 0 } = data || {};

  const { isCopied, handleCopy: copy } = useCopyToClipboard();

  const handleCopy = useCallback(() => copy(String(amount)), [copy, amount]);

  return (
    <>
      <button type="button" className={st.amountToPay} onClick={handleCopy}>
        <Text as="span" size="s" weight="w600">
          {t('cashier_page.payment_details.amount_to_pay')}
        </Text>

        <Text as="span" className={st.value} size="s" weight="w600">
          {amount}
          <Icon icon={iconCopy} />
        </Text>
      </button>

      <CopiedSuccess isShow={isCopied}>
        {t('copy_success')}
      </CopiedSuccess>
    </>
  );
};
