import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createSelectors } from '../lib/common';

interface ISiteConfigStore {
  isDesktop: boolean | null;
  isTablet: boolean | null;
  isMobile: boolean | null;
  setBreakpoints: (arg: Pick<ISiteConfigStore, 'isDesktop' | 'isTablet' | 'isMobile'>) => void;
}

const useSiteConfigStoreBase = create<ISiteConfigStore>()(
  immer((set) => ({
    isDesktop: null,
    isTablet: null,
    isMobile: null,
    setBreakpoints: (arg) => set((state) => ({ ...state, ...arg })),
  })),
);

export const useSiteConfigStore = createSelectors(useSiteConfigStoreBase);
