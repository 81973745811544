import { SyntheticEvent, forwardRef, PropsWithChildren, ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import { Spinner } from 'components';

import st from './Button.module.less';

const cn = classnames.bind(st);

export interface IButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'> {
  external?: boolean;
  fluid?: boolean;
  loading?: boolean;
  contentClassName?: string;
  theme?: 'clear' | 'primary' | 'secondary' | 'secondaryModal' | 'action';
  href?: string;
  onClick?: (event: SyntheticEvent) => void;
}

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<IButtonProps>>(({
  contentClassName,
  disabled,
  external: isExternal,
  fluid,
  loading,
  children,
  className,
  href,
  theme = 'primary',
  onClick,
}, ref) => {
  const btnClassName = cn(
    'button',
    {
      fluid,
      disabled,
    },
    theme,
    className,
  );

  if (href) {
    const linkProps = {
      className: btnClassName,
      onClick: disabled ? (event: SyntheticEvent) => event.preventDefault() : onClick,
    };

    if (isExternal) {
      return (
        <a href={href} {...linkProps} target="_blank" rel="noopener noreferrer">
          <span className={st.content}>
            {children}
          </span>
        </a>
      );
    }

    return (
      <Link to={href} {...linkProps}>
        <span className={st.content}>
          {children}
        </span>
      </Link>
    );
  }

  return (
    <button
      ref={ref}
      type="button"
      className={btnClassName}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={cn(st.content, contentClassName)}>
        {loading ? <Spinner theme="btn" /> : children}
      </span>
    </button>
  );
});
