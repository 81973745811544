import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { iconInfo } from 'assets';

import { Text } from '../Text';
import { Icon } from '../Icon';

import st from './InfoMessage.module.less';

interface IProps {
  className?: string;
}

export const InfoMessage = ({ className, children }: PropsWithChildren<IProps>) => (
  <div className={cn(st.infoMessage, className)}>
    <Icon icon={iconInfo} />

    <Text as="div" className={st.text} size="l" mobileSize="base">
      {children}
    </Text>
  </div>
);
