import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createSelectors } from 'lib/common';

interface IGameDemoStore {
  isDemo: boolean;
  setDemo: (isDemo: boolean) => void;

  isDemoPlayed: boolean;
  setDemoPlayed: (isDemo: boolean) => void;

  demoGameId: string;
  setDemoGameId: (gameId: string) => void;

  demoGameNumber: number | null;
  setDemoGameNumber: (demoGameNumber: number) => void;

  demoCheckStringHash: string;
  setDemoCheckStringHash: (demoCheckStringHash: string) => void;

  demoCheckString: string;
  setDemoCheckString: (demoCheckString: string) => void;

  demoWinNumber: number;
  setDemoWinNumber: (demoWinNumber: number) => void;

  demoSelectedNumbers: number[];
  setDemoSelectedNumbers: (number: number) => void;
  updateDemoSelectedNumbers: (demoSelectedNumbers: number[]) => void;

  resetDemoGame: () => void;
}

const useGameDemoBase = create<IGameDemoStore>()(
  immer((set) => ({
    isDemo: false,
    setDemo: (isDemo: boolean) => set((state) => ({ ...state, isDemo })),

    isDemoPlayed: false,
    setDemoPlayed: (isDemoPlayed: boolean) => set((state) => ({ ...state, isDemoPlayed })),

    demoGameId: '',
    setDemoGameId: (demoGameId: string) => set((state) => ({ ...state, demoGameId })),

    demoGameNumber: null,
    setDemoGameNumber: (demoGameNumber: number) => set((state) => ({ ...state, demoGameNumber })),

    demoCheckStringHash: '',
    setDemoCheckStringHash: (demoCheckStringHash: string) => set((state) => ({ ...state, demoCheckStringHash })),

    demoCheckString: '',
    setDemoCheckString: (demoCheckString: string) => set((state) => ({ ...state, demoCheckString })),

    demoWinNumber: 0,
    setDemoWinNumber: (demoWinNumber: number) => set((state) => ({ ...state, demoWinNumber })),

    demoSelectedNumbers: [],
    setDemoSelectedNumbers: (number) => set(({ demoSelectedNumbers }) => {
      const prevNumIndex = demoSelectedNumbers.indexOf(number);

      if (prevNumIndex > -1) {
        demoSelectedNumbers.splice(prevNumIndex, 1);
      } else {
        demoSelectedNumbers.push(number);
      }
    }),

    updateDemoSelectedNumbers: (demoSelectedNumbers) => set((state) => ({
      ...state,
      demoSelectedNumbers,
    })),

    resetDemoGame: () => set((state) => ({
      ...state,
      isDemoPlayed: false,
      demoGameId: '',
      demoGameNumber: null,
      demoCheckString: '',
      demoCheckStringHash: '',
      demoWinNumber: 0,
      demoSelectedNumbers: [],
    })),
  })),
);

export const useGameDemoStore = createSelectors(useGameDemoBase);
