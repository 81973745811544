import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconErrorColored, iconSuccessColored } from 'assets';
import { useDialogClose } from 'lib/contexts';

import { Button, Title, Icon, Dialog } from 'components';

import st from './ResultDialog.module.less';

interface IProps {
  error?: boolean;
  buttonText?: string;
  className?: string;
  href?: string;
  title?: ReactNode;
  onClick?: () => void;
}

export const ResultDialog = ({
  error: isError,
  className,
  buttonText,
  href,
  title,
  onClick,
}: IProps) => {
  const { t } = useTranslation();

  const closeDialog = useDialogClose();

  const handleClick = useCallback(() => {
    closeDialog();
    onClick?.();
  }, [closeDialog, onClick]);

  return (
    <Dialog
      mobileAs="modal"
      modalProps={{
        withCloseButton: false,
        withEscapePress: false,
        withOutsideClick: false,
      }}
    >
      <div className={cn(st.resultDialog, className)}>
        <div className={st.content}>
          <Icon className={st.icon} icon={isError ? iconErrorColored : iconSuccessColored} size={100} />

          {title && (
            <Title as="p" size="h3">
              {title}
            </Title>
          )}
        </div>

        <Button fluid className={st.btn} href={href} onClick={handleClick}>
          {buttonText || t('continue')}
        </Button>
      </div>
    </Dialog>
  );
};
