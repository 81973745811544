import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { useGame } from 'lib/hooks';
import { numberToMoney } from 'lib/utils';

import { Box, Text } from 'components';

import { ButtonRulesShow } from '../ButtonRulesShow';

import st from './Rules.module.less';

interface IProps {
  className?: string;
}

export const Rules = ({ className }: IProps) => {
  const { t } = useTranslation();

  const { status } = useGame();

  if (status) {
    return null;
  }

  return (
    <Box className={cn(st.rules, className)}>
      <Text size="xl" className={st.shortRule} weight="w500" mobileSize="xs">
        <Trans
          t={t}
          i18nKey="game.guess"
          components={{
            value: <Text as="span" className={st.value} weight="w900" />,
            desktopBlock: <span className={st.desktopBlock} />,
          }}
          values={{
            number: 1,
            prize: numberToMoney(100),
          }}
        />
      </Text>

      <ButtonRulesShow className={st.buttonRulesShow} />
    </Box>
  );
};
