import cn from 'classnames';

import { iconRefresh } from 'assets';

import { Icon } from 'components';

import st from './ButtonRefresh.module.less';

interface IProps {
  className?: string;
  onRefresh?: () => void;
}

export const ButtonRefresh = ({ className, onRefresh }: IProps) => (
  <button className={cn(st.buttonRefresh, className)} type="button" onClick={onRefresh}>
    <Icon className={st.iconRefresh} icon={iconRefresh} />
  </button>
);
