import cn from 'classnames';

import { ITextProps, TSVG } from 'lib/types';

import { Icon, Text } from 'components';

import st from './PseudoLink.module.less';

interface IProps extends ITextProps {
  className?: string;
  children: string;
  icon?: TSVG;
  onClick?: () => void;
}

export const PseudoLink = ({ className, children, icon, onClick, ...textProps }: IProps) => (
  <button type="button" className={cn(st.pseudoLink, className)} onClick={onClick}>
    <Text {...textProps} as="span" className={st.pseudoLinkText}>
      {children}
    </Text>

    {icon && <Icon icon={icon} size={16} />}
  </button>
);
