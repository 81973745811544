import { useCallback } from 'react';

import { useGame } from 'lib/hooks';
import { useAuthStore } from 'store';
import { useCurrentUserQuery } from 'api';
import { CHIP_PRICE } from 'lib/constants';

export const usePlayButton = () => {
  const isAuth = useAuthStore.use.isAuth();

  const {
    isDemo,
    isPlaying,
    status,
    selectedNumbers,
    playGame,
    playDemoGame,
    handleRefreshGame,
    handleRefreshDemoGame,
  } = useGame();

  const {
    isLoading: isLoadingBalance,
    data: { balance = 0 } = {},
    refetch: updateUserData,
  } = useCurrentUserQuery({ enabled: isAuth });

  const isLoading = isLoadingBalance || isPlaying;
  const selectedNumbersCount = selectedNumbers.length;
  const gamePrice = selectedNumbersCount * CHIP_PRICE;
  const isEnoughMoney = balance - gamePrice >= 0;

  const handlePlay = useCallback(({ onSuccess, onError }: {
    onSuccess?: () => void;
    onError?: () => void;
  } = {}) => () => {
    playGame()
      .then(() => {
        onSuccess?.();
        updateUserData();
      })
      .catch(onError);
  }, [playGame, updateUserData]);

  return {
    isDemo,
    isEnoughMoney,
    isLoading,
    addFunds: gamePrice - balance,
    selectedNumbersCount,
    status,
    playDemoGame,
    handlePlay,
    handleRefreshGame,
    handleRefreshDemoGame,
  };
};
