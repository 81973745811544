import { PropsWithChildren } from 'react';
import cn from 'classnames';

import st from './TableRow.module.less';

interface IProps {
  className?: string;
}

export const TableRow = ({ className, children }: PropsWithChildren<IProps>) => (
  <div className={cn(st.tableRow, className)}>
    {children}
  </div>
);
