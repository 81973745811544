import { SyntheticEvent, useCallback, useState } from 'react';

import { useAuthStore } from 'store';
import { usePassphraseQuery } from 'api';
import { useCopyToClipboard } from 'lib/hooks';

const INITIAL_STATE = {
  login: '',
  name: '',
  password: '',
};

export const useRegistration = () => {
  const credentials = useAuthStore.use.credentials();
  const { setCredentials } = useAuthStore.getState();

  const [name, setName] = useState('');
  const [errors, setErrors] = useState(INITIAL_STATE);

  const { data: { passphrase = '' } = {}, refetch: handleRefreshPhrase } = usePassphraseQuery();

  const { isCopied, handleCopy: copy } = useCopyToClipboard();

  const { login, password } = credentials;

  const handleChange = useCallback(({ currentTarget: { name: newName, value } }: SyntheticEvent<HTMLInputElement>) => {
    setErrors(INITIAL_STATE);

    if (newName === 'name') {
      setName(value);
      return false;
    }

    setCredentials({ ...credentials, [newName]: value });
  }, [credentials, setCredentials]);

  const handleCopy = useCallback(() => copy(passphrase), [copy, passphrase]);

  return {
    isCopied,
    submitDisabled: !login || !password,
    errors,
    login,
    name,
    passphrase,
    password,
    setErrors,
    handleChange,
    handleCopy,
    handleRefreshPhrase,
  };
};
