import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { IDialogState } from 'lib/types';
import { DialogStateProvider, useDialogsDestroy, useDialogsDestroyAll } from 'lib/contexts';
import { useLocation } from 'react-router-dom';

export const DialogContainer = ({
  uuid,
  isActive,
  isHidden,
  children,
}: PropsWithChildren<Omit<IDialogState, 'closeDialog' | 'closeDialogs'>>) => {
  const [prevPathname, setPrevPathname] = useState('');
  const { pathname } = useLocation();

  const destroyDialog = useDialogsDestroy();
  const destroyDialogs = useDialogsDestroyAll();

  const closeDialog = useCallback(() => destroyDialog(uuid), [destroyDialog, uuid]);

  useEffect(() => {
    if (!prevPathname) {
      setPrevPathname(pathname);
    } else if (pathname !== prevPathname) {
      closeDialog();
    }
  }, [prevPathname, pathname, closeDialog]);

  return (
    <DialogStateProvider
      closeDialog={closeDialog}
      closeDialogs={destroyDialogs}
      isActive={isActive}
      isHidden={isHidden}
      uuid={uuid}
    >
      {children}
    </DialogStateProvider>
  );
};
