import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useGame } from 'lib/hooks';
import { useNewGameQuery } from 'api';
import { useDialogClose } from 'lib/contexts';

import { Button, Dialog, Text, ValueRow } from 'components';

import st from './LossDialog.module.less';

export const LossDialog = () => {
  const { t } = useTranslation();
  const closeDialog = useDialogClose();

  const { checkString, winNumberString, resetGame } = useGame();

  const { refetch: startNewGame } = useNewGameQuery({
    enabled: false,
  });

  const handlePlayAgain = useCallback(() => {
    resetGame();
    startNewGame();
  }, [resetGame, startNewGame]);

  return (
    <Dialog>
      <Text className={st.title} size="xl" weight="w600">
        <Trans i18nKey="game.loss_dialog" />
      </Text>

      <ValueRow label={t('game.hidden_number')}>
        {winNumberString}
      </ValueRow>

      <ValueRow label={t('game.check_line')}>
        {checkString || ''}
      </ValueRow>

      <div className={st.buttons} onClick={closeDialog}>
        <Button onClick={handlePlayAgain}>
          {t('game.play_again_btn')}
        </Button>
      </div>
    </Dialog>
  );
};
