import { useQuery } from 'react-query';

import { api } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { INewGameDTO } from '../../contracts';

const fetchNewGame = (): Promise<INewGameDTO> => api.post('/api/games/new');

type TQueryFn = typeof fetchNewGame;

export const useNewGameQuery = <D = ExtractFnReturnType<TQueryFn>>(
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['new-game'],
  queryFn: fetchNewGame,
});
