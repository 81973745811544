import { useTranslation } from 'react-i18next';

import { useGame } from 'lib/hooks';
import { useAuthStore } from 'store';
import { useCurrentUserQuery } from 'api';
import { getMaxAvailableChips } from 'lib/utils';

import { Box, ButtonFinishDemoGame, Text } from 'components';

import PlayButton from '../PlayButton';
import { RandomButton } from '../RandomButton';

import st from './Control.module.less';

interface IProps {
  className?: string;
}

export const Control = ({ className }: IProps) => {
  const isAuth = useAuthStore.use.isAuth();

  const { t } = useTranslation();

  const { data: { balance = 0 } = {} } = useCurrentUserQuery({
    enabled: isAuth,
  });

  const { selectedNumbers } = useGame();

  return (
    <Box className={className}>
      <ButtonFinishDemoGame className={st.buttonFinishDemoGame} />

      <div className={st.rows}>
        <Text as="div" className={st.row} weight="w500">
          {t('game.chips_available')}
          <Text as="span" className={st.value} weight="w700">
            {getMaxAvailableChips({ balance, selectedNumbers })}
          </Text>
        </Text>

        <Text as="div" className={st.row} weight="w500">
          {t('game.chance')}
          <Text as="span" className={st.value} weight="w700">
            {selectedNumbers.length}
            %
          </Text>
        </Text>
      </div>

      <div className={st.buttons}>
        <PlayButton className={st.playBtn} />
        <RandomButton className={st.randomBtn} />
      </div>
    </Box>
  );
};
