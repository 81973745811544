import { ECashierTabs } from 'lib/types';

import { Deposit } from './Deposit';
import { Withdraw } from './Withdraw';

export const CONFIG = {
  [ECashierTabs.DEPOSIT]: {
    title: 'cashier_page.tabs.deposit',
    Component: Deposit,
  },
  [ECashierTabs.WITHDRAW]: {
    title: 'cashier_page.tabs.withdraw',
    Component: Withdraw,
  },
};
