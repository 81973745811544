import { useQuery } from 'react-query';

import { api } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { IPassphraseDTO } from '../../contracts';

const fetchPassphrase = (): Promise<IPassphraseDTO> => api.get('/api/users/passphrase');

type TQueryFn = typeof fetchPassphrase;

export const usePassphraseQuery = <D = ExtractFnReturnType<TQueryFn>>(
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['passphrase'],
  queryFn: fetchPassphrase,
});
