import { PropsWithChildren } from 'react';
import cn from 'classnames';

import st from './Table.module.less';

interface IProps {
  className?: string;
  id?: string;
}

export const Table = ({ className, children, id }: PropsWithChildren<IProps>) => (
  <div className={cn(st.table, className)} id={id}>
    {children}
  </div>
);
