import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { iconEdit, iconLogout } from 'assets';
import { useLogout } from 'lib/hooks';
import { ROUTES } from 'lib/constants';
import { changeUserPassword } from 'api';
import { useDialogsAdd } from 'lib/contexts';

import {
  Button,
  FormButtons,
  Icon,
  InnerPage,
  InnerPageHeader,
  Input,
  InputPassword,
  ResultDialog, Spinner,
  Text,
} from 'components';

import { useProfile } from './useProfile';

import st from './Profile.module.less';

export const Profile = () => {
  const { t } = useTranslation();

  const {
    isEdit,
    isLoading,
    errors,
    login,
    name,
    passphrase,
    newPassword,
    setErrors,
    handleCancelClick,
    handleChange,
    handleEditClick,
    setEdit,
  } = useProfile();

  const addDialog = useDialogsAdd();

  const handleLogout = useLogout();

  const handleSaveClick = useCallback(() => {
    changeUserPassword({ passphrase, newPassword })
      .then(() => {
        addDialog(<ResultDialog title={t('profile_page.change_success')} />);
        setEdit(false);
      })
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            passphrase: passphraseError = [],
            newPassword: newPasswordError = [],
          } = {},
        } = data;

        if (passphraseError.length || newPasswordError.length) {
          setErrors({
            passphrase: passphraseError?.[0] || '',
            newPassword: newPasswordError?.[0] || '',
          });

          return false;
        }

        addDialog(<ResultDialog error title={<Trans i18nKey="error_message" />} />);
      });
  }, [addDialog, newPassword, passphrase, setEdit, setErrors, t]);

  const content = isLoading ? (
    <div className={st.spinnerContainer}>
      <Spinner centered />
    </div>
  ) : (
    <>
      <div>
        <div className={st.row}>
          <Text size="s" className={st.label}>
            {t('login')}
          </Text>

          <Text weight="w500">
            {login}
          </Text>
        </div>

        <div className={st.row}>
          <Text size="s" className={st.label}>
            {t('profile_page.name')}
          </Text>

          <Text weight="w500">
            {name}
          </Text>
        </div>

        <div className={st.row}>
          <Text size="s" className={st.label}>
            {t('password')}
          </Text>

          <Text weight="w500" className={st.password}>
            •••••••••••••••••

            {!isEdit && (
              <button type="button" className={st.editBtn} onClick={handleEditClick}>
                <Icon className={st.iconEdit} icon={iconEdit} />
              </button>
            )}
          </Text>
        </div>
      </div>

      {isEdit && (
        <form className={st.form}>
          <Input
            errorMessage={errors.passphrase}
            name="passphrase"
            placeholder={t('profile_page.secret_phrase')}
            value={passphrase}
            onChange={handleChange}
          />

          <InputPassword
            errorMessage={errors.newPassword}
            autoComplete="new-password"
            name="newPassword"
            placeholder={t('profile_page.new_password')}
            value={newPassword}
            onChange={handleChange}
          />
        </form>
      )}

      <FormButtons
        buttonText={t('profile_page.save_changes')}
        submitDisabled={!newPassword.length || !passphrase.length}
        onCancel={handleCancelClick}
        onSubmit={handleSaveClick}
      >
        {!isEdit && (
          <Button fluid className={st.logoutBtn} theme="secondary" onClick={handleLogout}>
            <Icon className={st.iconLogout} icon={iconLogout} />
            {t('profile_page.logout')}
          </Button>
        )}
      </FormButtons>
    </>
  );

  return (
    <InnerPage backLink={ROUTES.PERSONAL_ACCOUNT}>
      <InnerPageHeader className={st.header} title={t('profile_page.title')} />

      {content}
    </InnerPage>
  );
};
