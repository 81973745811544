import { useEffect } from 'react';
import cn from 'classnames';

import { useAuthStore } from 'store';
import { useDemoGame } from 'lib/hooks';
import { useDialogsAdd } from 'lib/contexts';

import { DemoWinDialog, StartGameDialog } from 'components';

import { Win } from './Win';
import { Loss } from './Loss';
import { Rules } from './Rules';
import { Control } from './Control';
import { GameField } from './Field';
import { GameHeader } from './Header';
import { GameSidebar } from './Sidebar';

import { useInitGame } from './useInitGame';

import st from './Game.module.less';

export const Game = () => {
  const isAuth = useAuthStore.use.isAuth();

  const addDialog = useDialogsAdd();

  useInitGame();

  const { isDemoGamesPlayed } = useDemoGame();

  useEffect(() => {
    if (!isAuth && !isDemoGamesPlayed) {
      addDialog(<StartGameDialog />);
    }
  }, [isAuth, isDemoGamesPlayed, addDialog]);

  useEffect(() => addDialog(<DemoWinDialog />), [addDialog]);

  return (
    <div className={st.game}>
      <div className={st.content}>
        <GameHeader className={st.header} />

        <Win className={cn(st.result, st.win)} />
        <Loss className={cn(st.result, st.loss)} />
        <Rules className={st.rules} />

        <GameField />

        <Control className={st.control} />
      </div>

      <div className={st.sidebar}>
        <GameSidebar className={st.sidebarContent} />
      </div>
    </div>
  );
};
