import { useCallback, useState } from 'react';
import cn from 'classnames';

import { iconEye, iconEyeCrossed } from 'assets';

import { Input, IInputProps, Icon } from 'components';

import st from './InputPassword.module.less';

export const InputPassword = ({ containerClassName, className, disabled, ...inputProps }: IInputProps) => {
  const [isShownPassword, setVisibility] = useState(false);

  const handleToggleVisibility = useCallback(() => setVisibility(!isShownPassword), [isShownPassword]);

  return (
    <div className={cn(st.inputPassword, containerClassName)}>
      <Input
        className={cn(st.field, className)}
        disabled={disabled}
        {...inputProps}
        type={isShownPassword ? 'text' : 'password'}
      />

      <button
        type="button"
        className={cn(st.toggleVisibilityBtn, { [st.visible]: isShownPassword })}
        disabled={disabled}
        onClick={handleToggleVisibility}
      >
        <span className={st.iconContainer}>
          <Icon className={cn(st.icon, st.eye)} icon={iconEye} />
          <Icon className={cn(st.icon, st.eyeCrossed)} icon={iconEyeCrossed} />
        </span>
      </button>
    </div>
  );
};
