import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconInfo, imgConfetti, imgConfettiMobile } from 'assets';
import { useGame } from 'lib/hooks';
import { useDialogsAdd } from 'lib/contexts';

import { Box, PseudoLink, Text, ValueRow, WinDialog } from 'components';

import { ButtonRulesShow } from '../ButtonRulesShow';

import st from './Win.module.less';

interface IProps {
  className?: string;
}

export const Win = ({ className }: IProps) => {
  const addDialog = useDialogsAdd();

  const { t } = useTranslation();

  const { isWin, checkString, winNumberString } = useGame();

  const handleMoreClick = useCallback(() => addDialog(<WinDialog />), [addDialog]);

  if (!isWin) {
    return null;
  }

  return (
    <Box className={cn(st.box, className)}>
      <Text uppercase className={st.desktopTitle} size="xl" weight="w900">
        <Trans t={t} i18nKey="game.win" />
      </Text>

      <Text rubik className={st.mobileTitle} size="xs" weight="w700">
        {t('game.win_mobile')}
        <img className={st.imgMobile} src={imgConfettiMobile} alt="" />
      </Text>

      <ValueRow className={st.row} label={t('game.hidden_number')}>
        {winNumberString}
      </ValueRow>

      <ValueRow className={st.row} label={t('game.check_line')}>
        {checkString || ''}
      </ValueRow>

      <ButtonRulesShow className={st.buttonRulesShow} />

      <PseudoLink
        className={st.moreDetailsBtn}
        icon={iconInfo}
        size="s"
        mobileSize="xs"
        weight="w500"
        onClick={handleMoreClick}
      >
        {t('game.more_details')}
      </PseudoLink>

      <img className={st.img} src={imgConfetti} alt="" />
    </Box>
  );
};
