import { useMemo } from 'react';
import cn from 'classnames';

import { iconArrowRight } from 'assets';

import { Icon } from '../../layout';
import { Button, IButtonProps } from '../Button';

import { IPagination } from './types';
import { usePagination } from './usePagination';

import st from './Pagination.module.less';

interface IProps extends IPagination {
  centered?: boolean;
  withNavButtons?: boolean;
  activeClassName?: string;
  btnClassName?: string;
  className?: string;
  dotsClassName?: string;
  btnProps?: IButtonProps;
  getPageLink?: (page: number) => string;
  onClick: (page: number) => void;
}

export const Pagination = ({
  centered,
  withNavButtons,
  activeClassName,
  btnClassName,
  className,
  dotsClassName,
  btnProps,
  limit,
  offset,
  total: paginationTotal,
  getPageLink,
  onClick,
}: IProps) => {
  const { baseBtnProps, current, pages, total, handleClick } = usePagination({
    btnProps,
    limit,
    offset,
    total: paginationTotal,
    onClick,
  });

  const numbers = useMemo(
    () => pages.map((page, index) => {
      const isCurrent = current === page;
      const isDots = !parseInt(String(page), 10);
      const isDisabled = isCurrent || isDots;
      const currentPage = (Number(page) - 1) * limit;

      const classNames = cn(
        st.btn,
        {
          [st.active]: isCurrent,
          [st.dots]: !isCurrent && isDots,
          activeClassName: isCurrent && activeClassName,
          dotsClassName: !isCurrent && dotsClassName,
        },
        btnClassName,
      );

      return (
        <Button
          className={classNames}
          contentClassName={st.btnContent}
          disabled={isDisabled}
          key={isDots ? `empty_${index}` : page}
          {...(!isDisabled && getPageLink && { href: getPageLink(currentPage + 1) })}
          {...baseBtnProps}
          onClick={handleClick(currentPage)}
        >
          {page}
        </Button>
      );
    }),
    [activeClassName, baseBtnProps, btnClassName, current, dotsClassName, getPageLink, handleClick, limit, pages],
  );

  if (total < 2) {
    return null;
  }

  return (
    <nav className={cn(st.pagination, { [st.centered]: centered }, className)}>
      {withNavButtons && (
        <Button
          className={st.btn}
          contentClassName={st.btnContent}
          {...baseBtnProps}
          disabled={current === 1}
          onClick={handleClick(current * limit - limit * 2)}
          {...(current !== 1 && getPageLink && { href: getPageLink(current - 2) })}
        >
          <Icon className={st.iconArrowPrev} icon={iconArrowRight} />
        </Button>
      )}

      {numbers}

      {withNavButtons && (
        <Button
          className={st.btn}
          contentClassName={st.btnContent}
          {...baseBtnProps}
          disabled={current === total}
          onClick={handleClick(current * limit)}
          {...(current !== total && getPageLink && { href: getPageLink(current + 1) })}
        >
          <Icon icon={iconArrowRight} />
        </Button>
      )}
    </nav>
  );
};
