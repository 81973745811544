import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconCloseSmall } from 'assets';
import { useCheckHome } from 'lib/hooks';
import { useDialogsAdd } from 'lib/contexts';
import { useAuthStore, useGameStore } from 'store';

import { Icon, StartGameDialog } from 'components';

import st from './ButtonFinishDemoGame.module.less';

interface IProps {
  className?: string;
}

export const ButtonFinishDemoGame = ({ className }: IProps) => {
  const isAuth = useAuthStore.use.isAuth();

  const { resetGame } = useGameStore.getState();

  const { t } = useTranslation();

  const isGamePage = useCheckHome();

  const addDialog = useDialogsAdd();

  const handleFinishDemoClick = useCallback(() => {
    resetGame();
    addDialog(<StartGameDialog />);
  }, [addDialog, resetGame]);

  if (!isGamePage || isAuth) {
    return null;
  }

  return (
    <button type="button" className={cn(st.buttonFinishDemoGame, className)} onClick={handleFinishDemoClick}>
      {t('finish_demo_game')}
      <Icon icon={iconCloseSmall} />
    </button>
  );
};
