import { iconBTC, iconETH, iconUSDT } from 'assets';
import { Currency } from 'api';
import { TSVG } from 'lib/types';

export const CURRENCIES: Record<Currency, { slug: Currency; text: string; icon: TSVG }> = {
  [Currency.UsdtTrc20]: {
    slug: Currency.UsdtTrc20,
    text: 'USDT TRC-20',
    icon: iconUSDT,
  },
  [Currency.UsdtErc20]: {
    slug: Currency.UsdtErc20,
    text: 'USDT ERC-20',
    icon: iconUSDT,
  },
  [Currency.Btc]: {
    slug: Currency.Btc,
    text: Currency.Btc.toUpperCase(),
    icon: iconBTC,
  },
  [Currency.Eth]: {
    slug: Currency.Eth,
    text: Currency.Eth.toUpperCase(),
    icon: iconETH,
  },
  [Currency.Usd]: {
    slug: Currency.Usd,
    text: Currency.Usd.toUpperCase(),
    icon: iconUSDT,
  },
};
