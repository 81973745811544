import { Fragment, ReactNode } from 'react';
import cn from 'classnames';

import { useGame } from 'lib/hooks';

import { Text } from '../Text';

import st from './ValueRow.module.less';

interface IProps {
  className?: string;
  children: string;
  label?: ReactNode;
}

export const ValueRow = ({ className, label, children }: IProps) => {
  const { winNumber } = useGame();

  const stringArr = String(children).split('.');

  return (
    <div className={cn(st.valueRow, className)}>
      {label && (
        <Text className={st.label} size="s" weight="w500">
          {label}
        </Text>
      )}

      <Text className={st.number} weight="w500">
        {stringArr.map((part, index) => {
          if (part === String(winNumber)) {
            return (
              <Text as="span" weight="w700" color="black" key={part}>
                {part}
                .
              </Text>
            );
          }

          return (
            <Fragment key={part}>
              {part}
              {index !== stringArr.length - 1 && '.'}
            </Fragment>
          );
        })}
      </Text>
    </div>
  );
};
