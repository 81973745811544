import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconCopy } from 'assets';
import { useDepositCrypto } from 'api';
import { useCopyToClipboard } from 'lib/hooks';
import { CopiedSuccess, Icon, Text } from 'components';

import { useCashierStore } from '../../../store';

import st from './WalletAddress.module.less';

interface IProps {
  className?: string;
}

export const WalletAddress = ({ className }: IProps) => {
  const amountUsd = useCashierStore.use.amountUsd();
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { t } = useTranslation();

  const { data: { address = '', qrCodeUrl } = {} } = useDepositCrypto({ amountUsd, currency: currentCurrency }, {
    enabled: false,
  });

  const { isCopied, handleCopy: copy } = useCopyToClipboard();

  const handleCopy = useCallback(() => copy(address), [copy, address]);

  return (
    <Text as="div" className={cn(st.walletAddress, className)} size="s">
      <span className={st.imgContainer}>
        <img src={qrCodeUrl} alt="" />
      </span>

      <div className={st.content}>
        <Text className={st.title} color="grayBlue">
          {t('cashier_page.payment_details.wallet_address')}
        </Text>

        <div className={st.addressContainer}>
          <span className={st.address}>
            {address}
          </span>

          <button className={st.copyBtn} type="button" onClick={handleCopy}>
            <Icon icon={iconCopy} />
          </button>
        </div>

        <Text className={st.text} color="grayBlue" weight="w500" mobileSize="xs">
          {t('cashier_page.payment_details.wallet_address_instruction')}
        </Text>
      </div>

      <CopiedSuccess isShow={isCopied}>
        {t('copy_success')}
      </CopiedSuccess>
    </Text>
  );
};
