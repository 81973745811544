import { CHIP_PRICE, MAX_GAME_CHIPS } from '../constants';

export const isNumeric = (num: any): num is number => {
  const float = parseFloat(num);

  return !Number.isNaN(float) && Number.isFinite(float) && String(num).length === String(float).length;
};

export const numberAddSpace = (value: number | string): string => (
  String(value).replace(/(\d)(?=(\d{3})+\b)/g, '$1\xa0')
);

export const numberToMoney = (value: string | number): string => `$${numberAddSpace(value)}`;

export const numberToChips = (value: string | number = 0): number => Math.floor(Number(value) / CHIP_PRICE);

export const toFixed = (fractionDigits: number) => (n: number | string): string => {
  const num = typeof n === 'string' ? parseFloat(n) : n;

  return num.toFixed(fractionDigits);
};

export const toFixed8 = toFixed(8);

export const getMaxAvailableChips = ({ balance, selectedNumbers }: { balance: number, selectedNumbers: number[] }) => {
  const maxChips = balance > MAX_GAME_CHIPS ? MAX_GAME_CHIPS : numberToChips(balance);
  const maxAvailableChips = maxChips - selectedNumbers.length;

  return maxAvailableChips > 0 ? maxAvailableChips : 0;
};

export const getRandomInt = (from: number, to: number): number => {
  const min = Math.ceil(from);
  const max = Math.floor(to + 1);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getArrayOfRandomNumbers = ({ from, to, size, func, excluded = [] }: {
  from: number,
  to: number,
  size: number,
  excluded?: number[],
  func: (from: number, to: number) => number;
}): number[] => {
  const res: number[] = [];

  while (res.length < size) {
    const num = func(from, to);

    if (!res.includes(num) && !excluded.includes(num)) {
      res.push(num);
    }
  }

  return res;
};
