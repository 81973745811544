import { ReactElement, useCallback, useState } from 'react';
import constate from 'constate';
import { nanoid } from 'nanoid';

import { IAddDialogConfig, IDialogsState, IDialogState, IReturnedDialogsState } from 'lib/types';

const useDialogsStateContext = (): IReturnedDialogsState => {
  const [dialogs, setDialogs] = useState<IDialogsState[]>([]);

  const addDialog = useCallback((content: ReactElement, config?: IAddDialogConfig) => {
    const { uuid, replaceLast, permanent = true } = config || {};

    setDialogs((prev) => {
      if (uuid && prev.some((_prev) => _prev.uuid === uuid)) {
        return prev;
      }

      const nextDialog: IDialogsState = { uuid: uuid || nanoid(), permanent, content };

      if (replaceLast) {
        prev.splice(replaceLast ? -1 : 9999, 1, nextDialog);

        return [...prev];
      }

      return [...prev, nextDialog];
    });
  }, []);

  const destroyDialog = useCallback((id?: string) => setDialogs((prev) => prev.filter(({ uuid }) => uuid !== id)), []);

  const destroyAllDialogs = useCallback(() => setDialogs([]), []);

  return {
    dialogs,
    addDialog,
    destroyDialog,
    destroyAllDialogs,
  };
};

const [DialogsStateProvider, useDialogs, useDialogsAdd, useDialogsDestroy, useDialogsDestroyAll] = constate(
  useDialogsStateContext,
  ({ dialogs }: IReturnedDialogsState) => dialogs,
  ({ addDialog }: IReturnedDialogsState) => addDialog,
  ({ destroyDialog }: IReturnedDialogsState) => destroyDialog,
  ({ destroyAllDialogs }: IReturnedDialogsState) => destroyAllDialogs,
);

export { DialogsStateProvider, useDialogs, useDialogsAdd, useDialogsDestroy, useDialogsDestroyAll };

const useDialogStateContext = (state: IDialogState): IDialogState => state;

const [DialogStateProvider, useDialog, useDialogClose] = constate(
  useDialogStateContext,
  (state: IDialogState) => state,
  ({ closeDialog }: IDialogState) => closeDialog,
);

export { DialogStateProvider, useDialog, useDialogClose };
