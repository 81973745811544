import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

export const useCopyToClipboard = () => {
  const [isCopied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const handleCopy: CopyFn = async (text) => {
    try {
      (window as any)?.NativeAndroid?.copyToClipboard?.(text);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Copy NativeAndroid', error);
    }

    try {
      if (navigator?.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        const textField = document.createElement('textarea');

        textField.innerText = text;

        document.body.appendChild(textField);

        if (window.navigator.platform === 'iPhone') {
          textField.setSelectionRange(0, 99999);
        } else {
          textField.select();
        }

        document.execCommand?.('copy');

        textField.remove();
      }

      setCopiedText(text);

      setCopied(true);

      setTimeout(() => setCopied(false), 3000);

      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Copy failed', error);

      setCopiedText(null);

      return false;
    }
  };

  return { isCopied, copiedText, handleCopy };
};
