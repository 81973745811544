import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { iconArrowRight } from 'assets';
import { useGoBack } from 'lib/hooks';

import { Icon, Title } from 'components';

import st from './InnerPageHeader.module.less';

interface IProps {
  className?: string;
  title?: string;
  backLink?: string;
}

export const InnerPageHeader = ({ backLink, className, children, title }: PropsWithChildren<IProps>) => {
  const goBack = useGoBack();

  return (
    <div className={cn(st.innerPageHeader, className)}>
      {backLink ? (
        <Link to={backLink} className={st.backBtn}>
          <Icon className={st.iconArrowBack} icon={iconArrowRight} />
        </Link>
      ) : (
        <button className={st.backBtn} type="button" onClick={goBack}>
          <Icon className={st.iconArrowBack} icon={iconArrowRight} />
        </button>
      )}

      <Title className={st.title} mobileSize="xl">
        {title}
      </Title>

      {children}
    </div>
  );
};
