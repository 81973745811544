import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { logoutUser } from 'api';
import { useCookies } from 'lib/hooks';
import { ROUTES } from 'lib/constants';
import { useAuthStore, useGameStore } from 'store';

export const useLogout = () => {
  const { resetGame } = useGameStore.getState();
  const { setAuthorized, setCredentials } = useAuthStore.getState();

  const navigate = useNavigate();
  const { removeLotopolyCookie } = useCookies();

  return useCallback(() => {
    logoutUser().finally(() => {
      removeLotopolyCookie('ACCESS_TOKEN');
      removeLotopolyCookie('REFRESH_TOKEN');

      setAuthorized(false);
      setCredentials({ login: '', password: '' });

      resetGame();

      navigate(ROUTES.GAME);
    });
  }, [navigate, removeLotopolyCookie, resetGame, setAuthorized, setCredentials]);
};
