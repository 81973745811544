import { useQuery } from 'react-query';

import { api } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { IGameDTO } from '../../contracts';

const fetchNewDemoGame = (): Promise<IGameDTO> => api.post('/api/games/demo');

type TQueryFn = typeof fetchNewDemoGame;

export const useNewDemoGameQuery = <D = ExtractFnReturnType<TQueryFn>>(
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['new-demo-game'],
  queryFn: fetchNewDemoGame,
});
