import { createElement, PropsWithChildren } from 'react';
import cn from 'classnames';

import { useSiteConfigStore } from 'store';
import { ITextSizes, ITitleSizes } from 'lib/types';

import st from './Title.module.less';

interface IProps {
  uppercase?: boolean;
  as?: 'h1' | 'h2' | 'h3' | 'p' | 'span';
  className?: string;
  size?: ITitleSizes;
  mobileSize?: ITitleSizes | ITextSizes;
}

export const Title = ({
  children,
  as = 'h1',
  size = 'h1',
  mobileSize = '',
  className: outerClassName,
  uppercase = true,
  ...rest
}: PropsWithChildren<IProps>) => {
  const isDesktop = useSiteConfigStore.use.isDesktop();
  const currentSize = isDesktop ? size : mobileSize || size;

  return createElement(
    as,
    {
      className: cn(st.title, st[currentSize], { [st.uppercase]: uppercase }, outerClassName),
      ...rest,
    },
    children,
  );
};
