import { useQuery } from 'react-query';

import { api } from 'lib/utils';

import { IGameDTO, IPlayGameDTO } from '../../contracts';
import { ExtractFnReturnType, QueryConfigWithSelect } from '../../../lib/query';

export const fetchPlayGame = (id: string, selectedNumbers: IPlayGameDTO): Promise<IGameDTO> => (
  api.put(`/api/games/${id}/play`, selectedNumbers)
);

type TQueryFn = typeof fetchPlayGame;

export const usePlayGameQuery = <D = ExtractFnReturnType<TQueryFn>>(
  id: string,
  selectedNumbers: IPlayGameDTO,
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['play-game', id],
  queryFn: () => fetchPlayGame(id, selectedNumbers),
});
