import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { cancelTransaction, useDepositCrypto } from 'api';
import { ROUTES } from 'lib/constants';

import { Box, InnerPage, InnerPageHeader } from 'components';

import { Tabs } from './Tabs';

import { useView } from './hooks';
import { CONFIG } from './config';
import { useCashierStore } from './store';

import st from './Cashier.module.less';

export const Cashier = () => {
  const isPaidPrepared = useCashierStore.use.isPaidPrepared();
  const amountUsd = useCashierStore.use.amountUsd();
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { setPaidPrepared } = useCashierStore.getState();

  const { t } = useTranslation();

  const view = useView();

  const { data, remove } = useDepositCrypto({ amountUsd, currency: currentCurrency }, {
    enabled: false,
  });

  const Content = useMemo(() => CONFIG[view]?.Component, [view]);

  const { transactionId = '' } = data || {};

  const handleBackLinkClick = useCallback(() => {
    cancelTransaction(transactionId)
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => {
        remove();
        setPaidPrepared(false);
      });
  }, [remove, transactionId, setPaidPrepared]);

  return (
    <InnerPage backLink={ROUTES.GAME} {...isPaidPrepared ? { onBackLinkClick: handleBackLinkClick } : {}}>
      <InnerPageHeader
        className={st.header}
        backLink={ROUTES.PERSONAL_ACCOUNT}
        title={t('cashier_page.title')}
      />

      <Tabs className={st.tabs} />

      <Box className={st.content}>
        {Content ? <Content /> : null}
      </Box>
    </InnerPage>
  );
};
