import { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconArrowTransactions, iconCloseSmall } from 'assets';
import { TransactionStatus, TransactionType } from 'api';
import { CURRENCIES } from 'lib/models';
import { formatAddress, scrollToSelector } from 'lib/utils';
import { useDialogsAdd } from 'lib/contexts';

import { Icon, Pagination, ResultDialog, Spinner, Table, TableCell, TableRow } from 'components';

import NoData from '../NoData';

import { TABLE_HEADER } from './config';
import { useTransactions } from './useTransactions';

import st from './Transactions.module.less';

interface IProps {
  className?: string;
}

export const Transactions = ({ className }: IProps) => {
  const { t } = useTranslation();

  const {
    isLoading,
    isPaymentSuccessful,
    isPaymentFailed,
    isCancelTransactionError,
    items,
    totalItems,
    page,
    pageSize,
    handleCancelClick,
    handleCancelErrorClick,
    handlePagination,
  } = useTransactions();

  const addDialog = useDialogsAdd();

  const scrollToTransactions = useCallback(() => scrollToSelector({
    selector: '#transactions',
    offset: -30,
  }), []);

  useEffect(() => {
    if (isCancelTransactionError) {
      addDialog(
        <ResultDialog
          error
          buttonText={t('close')}
          title={<Trans i18nKey="personal_account.transactions.failed" />}
          onClick={handleCancelErrorClick}
        />,
      );
    }
  }, [isCancelTransactionError, addDialog, handleCancelErrorClick, t]);

  useEffect(() => {
    if (isPaymentSuccessful) {
      addDialog(
        <ResultDialog
          title={t('personal_account.transactions.payment_success')}
          onClick={scrollToTransactions}
        />,
      );
    }

    if (isPaymentFailed) {
      addDialog(
        <ResultDialog
          error
          title={t('personal_account.transactions.payment_failed')}
          onClick={scrollToTransactions}
        />,
      );
    }
  }, [isPaymentFailed, isPaymentSuccessful, addDialog, scrollToTransactions, t]);

  const header = useMemo(() => TABLE_HEADER.map((row) => (
    <TableCell className={st.th} type="th" key={row}>
      {t(row)}
    </TableCell>
  )), [t]);

  const content = useMemo(() => items.map(({
    id,
    amount,
    status,
    currency,
    address,
    type,
  }) => {
    const { icon } = CURRENCIES?.[currency] || {};

    return (
      <TableRow key={id}>
        <TableCell className={st.amount} size="xs">
          <Icon
            className={cn(st.iconArrowTransactions, { [st.down]: type === TransactionType.Deposit })}
            icon={iconArrowTransactions}
            size={14}
          />

          {icon && <Icon icon={icon} size={16} />}
          {amount}
        </TableCell>

        <TableCell className={cn(st.status, st[status.toLowerCase()])} size="s">
          {t(`transaction_statuses.${status}`)}
        </TableCell>

        <TableCell className={st.wallet} size="s">
          {formatAddress(address || '')}

          {status === TransactionStatus.Created && (
            <button className={st.cancelBtn} type="button" onClick={handleCancelClick(id)}>
              <Icon icon={iconCloseSmall} size={12} />
            </button>
          )}
        </TableCell>
      </TableRow>
    );
  }), [items, handleCancelClick, t]);

  if (isLoading) {
    return (
      <div className={st.spinnerContainer}>
        <Spinner centered />
      </div>
    );
  }

  return (
    <Table className={className} id="transactions">
      {items.length ? (
        <>
          <TableRow>
            {header}
          </TableRow>

          {content}

          <Pagination
            centered
            className={st.pagination}
            limit={1}
            offset={page - 1}
            total={totalItems / pageSize}
            onClick={handlePagination}
          />
        </>
      ) : (
        <NoData />
      )}
    </Table>
  );
};
