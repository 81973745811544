import { PropsWithChildren, useRef } from 'react';
import { BottomSheet as RSBottomSheet, BottomSheetProps, BottomSheetRef } from 'react-spring-bottom-sheet';
import cn from 'classnames';
import 'react-spring-bottom-sheet/dist/style.css';

import st from './BottomSheet.module.less';

export type TBottomSheetProps = Pick<
  BottomSheetProps,
  'className' | 'scrollLocking' | 'hidden' | 'onDismiss' | 'header' | 'footer'
> & {
  isOpened?: boolean;
};

export const BottomSheet = ({
  isOpened,
  children,
  className,
  footer,
  header,
  hidden,
  scrollLocking,
  onDismiss,
}: PropsWithChildren<TBottomSheetProps>) => {
  const sheetRef = useRef<BottomSheetRef>(null);

  const headerMarkup = (
    <>
      <button className={st.swipeBtn} type="button" onClick={onDismiss} />
      {header}
    </>
  );

  return (
    <RSBottomSheet
      blocking={hidden ? false : scrollLocking}
      className={cn(st.container, className)}
      footer={footer}
      header={headerMarkup}
      hidden={hidden}
      open={!!isOpened}
      ref={sheetRef}
      scrollLocking={hidden ? false : scrollLocking}
      onDismiss={onDismiss}
    >
      {children}
    </RSBottomSheet>
  );
};
