import { SyntheticEvent, useCallback, useState } from 'react';

import { useCurrentUserQuery } from 'api';
import { handleFieldChange } from 'lib/common';

const INITIAL_STATE = {
  passphrase: '',
  newPassword: '',
};

export const useProfile = () => {
  const [isEdit, setEdit] = useState(false);
  const [errors, setErrors] = useState(INITIAL_STATE);
  const [{ passphrase, newPassword }, setValues] = useState(INITIAL_STATE);

  const { isLoading, data: { login, name } = {} } = useCurrentUserQuery();

  const handleChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setErrors(INITIAL_STATE);
    handleFieldChange(setValues)(event);
  }, []);

  const handleEditClick = useCallback(() => setEdit(true), []);

  const handleCancelClick = useCallback(() => setEdit(false), []);

  return {
    isEdit,
    isLoading,
    errors,
    login,
    name,
    newPassword,
    passphrase,
    setErrors,
    handleCancelClick,
    handleChange,
    handleEditClick,
    setEdit,
  };
};
