import { PropsWithChildren, useRef } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';

import { iconClose } from 'assets';
import { IModalProps } from 'lib/types';
import { clickStopPropagationEvent, noop } from 'lib/utils';
import { useOnEscapePress, useSlipOffTouches } from 'lib/hooks';

import { Icon, Overlay, Portal } from 'components';

import st from './Modal.module.less';

export const Modal = ({
  isActive,
  isClosableSwipe = false,
  isHidden,
  withOutsideClick = true,
  withEscapePress = true,
  withCloseButton = true,
  children,
  className,
  closeBtnClassName,
  onClose,
}: PropsWithChildren<IModalProps>) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnEscapePress(withEscapePress && onClose && isActive && !isHidden ? onClose : noop);

  useOnclickOutside(() => onClose?.(), {
    refs: [ref],
    disabled: !withOutsideClick || !onClose || !isActive || isHidden,
  });

  const {
    handleStop: handleTouchStop,
    handleStart: handleTouchStart,
    handleMove: handleTouchMove,
    deltaY,
  } = useSlipOffTouches({ isActive: isClosableSwipe, onClose });

  return (
    <Portal isShown withLockBodyScroll={isActive}>
      <Overlay
        className={cn({ [st.hidden]: isHidden })}
        onClick={clickStopPropagationEvent}
        onTouchStart={clickStopPropagationEvent}
      >
        <div
          aria-modal="true"
          className={cn(st.content, className)}
          ref={ref}
          role="dialog"
          style={deltaY ? { marginTop: deltaY } : undefined}
          onTouchEnd={handleTouchStop}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
        >
          {withCloseButton && (
            <button type="button" className={cn(st.btn, closeBtnClassName)} onClick={onClose}>
              <Icon icon={iconClose} />
            </button>
          )}

          {children}
        </div>
      </Overlay>
    </Portal>
  );
};
