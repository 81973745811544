import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDepositCrypto } from 'api';
import { searchParams } from 'lib/utils';
import { MIN_DEPOSIT_AMOUNT } from 'lib/constants';

import { useCashierStore } from '../../store';

export const useCreditAmount = () => {
  const amountUsd = useCashierStore.use.amountUsd();
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { setAmountUsd, setPaidPrepared } = useCashierStore.getState();

  const { search } = useLocation();

  const [error, setError] = useState('');
  const [animated, setAnimated] = useState(false);

  const { isFetching, data, refetch } = useDepositCrypto({ amountUsd, currency: currentCurrency }, {
    enabled: false,
  });

  const fromGame = searchParams.get(search, 'fromGame') || '';

  useEffect(() => {
    if (fromGame) {
      setAmountUsd(Number(fromGame));
    }
  }, [fromGame, setAmountUsd]);

  useEffect(() => {
    if (data) {
      setPaidPrepared(true);
    }
  }, [data, setPaidPrepared]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (amountUsd && amountUsd < MIN_DEPOSIT_AMOUNT) {
        setAnimated(true);
        setTimeout(() => setAnimated(false), 3000);
      }
    }, 800);
    return () => clearTimeout(timeOutId);
  }, [amountUsd]);

  const handleChange = useCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    setAmountUsd(Number(value.replace('$', '')) || 0);
  }, [setAmountUsd]);

  return {
    isLoading: isFetching,
    animated,
    amountUsd,
    error,
    fetchDepositCrypto: refetch,
    handleChange,
  };
};
