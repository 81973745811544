import { useDialogs } from 'lib/contexts';

import { DialogContainer } from '../DialogContainer';

export const DialogsContainer = () => {
  const dialogs = useDialogs();

  return (
    <>
      {dialogs.map(({ uuid, permanent, content }, index) => (
        <DialogContainer
          isActive={index === dialogs.length - 1}
          isHidden={!permanent && index !== dialogs.length - 1}
          key={uuid}
          uuid={uuid}
        >
          {content}
        </DialogContainer>
      ))}
    </>
  );
};
