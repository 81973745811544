import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { iconCopy } from 'assets';
import { numberToMoney } from 'lib/utils';
import { CARD_NUMBER } from 'lib/constants';
import { useCopyToClipboard } from 'lib/hooks';
import { CopiedSuccess, Icon, Text } from 'components/layout';

import st from './TransferInfo.module.less';

interface IProps {
  amount: number;
  className?: string;
}

export const TransferInfo = ({ amount, className }: IProps) => {
  const { t } = useTranslation();

  const { isCopied, handleCopy: copy } = useCopyToClipboard();

  const handleCopy = useCallback(() => copy(CARD_NUMBER.replace(/\s/g, '')), [copy]);

  return (
    <div className={className}>
      <Text className={st.label} weight="w700">
        {t('payment_dialog.transfer_info.title')}
      </Text>

      <div className={st.requisites}>
        <Text className={st.title} weight="w600">
          {t('payment_dialog.transfer_info.card_number')}
          :&nbsp;
          <span className={st.number}>
            {CARD_NUMBER}
          </span>
        </Text>

        <Text className={st.text} size="s" weight="w600">
          OCTOBANK, Sevara Rzambetova
        </Text>

        <Text className={st.text} size="s" weight="w600">
          {t('amount')}
          :&nbsp;
          {numberToMoney(amount)}
        </Text>

        <button className={st.copyBtn} type="button" onClick={handleCopy}>
          <Icon icon={iconCopy} />
        </button>
      </div>

      <CopiedSuccess isShow={isCopied}>
        {t('copy_success')}
      </CopiedSuccess>
    </div>
  );
};
