import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { iconCopy } from 'assets';
import { registerUser } from 'api';
import { ROUTES } from 'lib/constants';
import { useDialogsAdd } from 'lib/contexts';
import { useLogin, useRedirectToGame, useRegistration } from 'lib/hooks';

import {
  ButtonRefresh,
  CopiedSuccess,
  FormButtons,
  Icon,
  InnerPage,
  Input,
  InputPassword,
  ResultDialog,
  Text,
} from 'components';

import { AuthTabs } from '../Tabs';
import { AuthWarning } from '../Warning';

import st from './Registration.module.less';

export const Registration = () => {
  const { t } = useTranslation();

  const {
    isCopied,
    submitDisabled,
    errors,
    login,
    name,
    passphrase,
    password,
    setErrors,
    handleChange,
    handleCopy,
    handleRefreshPhrase,
  } = useRegistration();

  useRedirectToGame();

  const addDialog = useDialogsAdd();

  const { handleLogin } = useLogin();

  const handleRegister = useCallback(() => {
    registerUser({
      languageCode: 'en',
      login,
      name,
      passphrase,
      password,
    })
      .then(() => {
        addDialog(<ResultDialog title={t('registration_page.success')} onClick={handleLogin} />);
      })
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            login: loginErrors = [],
            name: nameErrors = [],
            password: passwordErrors = [],
          } = {},
        } = data;

        if (loginErrors.length) {
          setErrors({
            ...errors,
            login: loginErrors[0],
          });
        }

        if (nameErrors.length) {
          setErrors({
            ...errors,
            name: nameErrors[0],
          });
        }

        if (passwordErrors.length) {
          setErrors({
            ...errors,
            password: passwordErrors[0],
          });
        }

        if (loginErrors.length || nameErrors.length || passwordErrors.length) {
          return false;
        }

        addDialog(
          <ResultDialog error title={<Trans i18nKey="registration_page.error" />} />,
        );
      });
  }, [addDialog, errors, login, name, passphrase, password, setErrors, handleLogin, t]);

  return (
    <InnerPage backLink={ROUTES.GAME}>
      <AuthTabs className={st.authTabs} />

      <AuthWarning />

      <form className={st.form}>
        <div className={st.row}>
          <Input
            autoComplete="username"
            errorMessage={errors.login}
            name="login"
            placeholder={t('login')}
            value={login}
            onChange={handleChange}
          />

          <Input
            autoComplete="given-name"
            errorMessage={errors.name}
            name="name"
            placeholder={t('registration_page.your_name')}
            value={name}
            onChange={handleChange}
          />

          <InputPassword
            autoComplete="new-password"
            errorMessage={errors.password}
            name="password"
            placeholder={t('password')}
            value={password}
            onChange={handleChange}
          />
        </div>

        <div className={st.row}>
          <div>
            <Text className={st.description} weight="w500">
              {t('registration_page.secret_word')}
            </Text>

            <Text className={st.text}>
              <Trans i18nKey="registration_page.secret_phrase" />
            </Text>
          </div>

          <div className={st.phraseRow}>
            <div className={st.phraseField}>
              <Input readOnly className={st.phraseInput} value={passphrase} />

              <button className={st.copyBtn} type="button" onClick={handleCopy}>
                <Icon icon={iconCopy} />
              </button>
            </div>

            <ButtonRefresh className={st.btnRefresh} onRefresh={handleRefreshPhrase} />
          </div>
        </div>
      </form>

      <FormButtons
        buttonText={t('registration_page.registration_btn')}
        cancelHref={ROUTES.GAME}
        submitDisabled={submitDisabled}
        onSubmit={handleRegister}
      />

      <CopiedSuccess isShow={isCopied} />
    </InnerPage>
  );
};
