import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { destroyCookie } from 'nookies';

import { useAuthStore } from 'store';

export const AXIOS_CONFIG: AxiosRequestConfig = {
  responseType: 'json',
};

const axiosRequestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { authToken } = useAuthStore.getState();

  if (authToken) {
    config.headers.set('Authorization', `Bearer ${authToken}`);
  }

  return config;
};

export const axiosResponseInterceptor = ({ data = {} }: AxiosResponse) => Promise.resolve(data);

export const axiosErrorInterceptor = (error: AxiosError) => {
  const { status } = error.response || {};

  if (!!status && [401, 403].includes(status)) {
    destroyCookie(null, 'ACCESS_TOKEN', { path: '/' });
    destroyCookie(null, 'REFRESH_TOKEN', { path: '/' });
    useAuthStore.getState().setAuthorized(false);
    useAuthStore.getState().setAuthToken(null);
  }

  return Promise.reject(error);
};

const api = Axios.create({
  ...AXIOS_CONFIG,
});

api.interceptors.request.use(axiosRequestInterceptor);
api.interceptors.response.use(axiosResponseInterceptor, axiosErrorInterceptor);

export default api;
