import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconArrowBack } from 'assets';
import { useGoBack } from 'lib/hooks';

import { Icon } from 'components';

import st from './BackLink.module.less';

interface IProps {
  className?: string;
  backLink?: string;
  onClick?: () => void;
}

export const BackLink = ({ backLink, className, onClick }: IProps) => {
  const { t } = useTranslation();

  const goBack = useGoBack();

  const content = (
    <span className={st.text}>
      <Icon icon={iconArrowBack} />
      {t('back')}
    </span>
  );

  if (backLink) {
    return (
      <Link className={cn(st.backLink, className)} to={backLink}>
        {content}
      </Link>
    );
  }

  if (onClick) {
    return (
      <button type="button" className={cn(st.backLink, className)} onClick={onClick}>
        {content}
      </button>
    );
  }

  return (
    <button type="button" className={cn(st.backLink, className)} onClick={goBack}>
      {content}
    </button>
  );
};
