import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSiteConfigStore } from 'store';
import { useDialogClose } from 'lib/contexts';
import { Button, Input } from 'components/ui';
import { MIN_DEPOSIT_AMOUNT } from 'lib/constants';
import { ButtonPaid, Instruction, Text, Timer, Title } from 'components/layout';

import { Dialog } from '../Dialog';

import { imgMastercard, imgVisa } from './img';
import { TransferInfo } from './TransferInfo';

import st from './DialogPayment.module.less';

export const DialogPayment = () => {
  const isMobile = useSiteConfigStore.use.isMobile();

  const { t } = useTranslation();

  const closeDialog = useDialogClose();

  const [isBtnClicked, setBtnClicked] = useState(false);
  const [amountUsd, setAmountUsd] = useState(0);

  const handleChange = useCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    setAmountUsd(Number(value.replace('$', '')) || 0);
  }, [setAmountUsd]);

  const handleClick = useCallback(() => setBtnClicked(true), [setBtnClicked]);
  const handlePaidClick = useCallback(() => setTimeout(closeDialog, 3000), [closeDialog]);

  return (
    <Dialog
      mobileAs="modal"
      modalProps={{ className: st.dialogPayment, withCloseButton: !!isMobile, closeBtnClassName: st.btnClose }}
    >
      <div className={st.header}>
        <Title size="h2">
          {t('cashier_page.payment_details.title')}
        </Title>

        <Timer />
      </div>

      <Text className={st.successText} mobileSize="s">
        {t('cashier_page.payment_details.success')}
      </Text>

      {!isMobile && (
        <Instruction
          title={t('payment_dialog.instruction.title')}
          className={st.instruction}
          items={[
            t('payment_dialog.instruction.rule_1'),
            t('payment_dialog.instruction.rule_2'),
            t('payment_dialog.instruction.rule_3'),
          ]}
        />
      )}

      <label className={st.label} htmlFor="amountUsd">
        {t('payment_dialog.instruction.label')}
      </label>

      <div className={st.field}>
        <Input
          className={st.input}
          disabled={isBtnClicked}
          value={amountUsd ? `$${String(amountUsd)}` : ''}
          name="amountUsd"
          placeholder={t('cashier_page.credit_amount.placeholder')}
          type="text"
          id="amountUsd"
          onChange={handleChange}
        />

        <div className={st.logos}>
          <img className={st.img} src={imgVisa} alt="" width={61} height={25} />
          <img className={st.img} src={imgMastercard} alt="" width={43} height={25} />
        </div>
      </div>

      {isBtnClicked && <TransferInfo amount={amountUsd} className={st.transferInfo} />}

      <div className={st.buttons}>
        <Button fluid theme="secondary" onClick={closeDialog}>
          {t('cancel')}
        </Button>

        {isBtnClicked ? (
          <ButtonPaid onClick={handlePaidClick} />
        ) : (
          <Button fluid disabled={Number(amountUsd) < MIN_DEPOSIT_AMOUNT} onClick={handleClick}>
            {t('payment_dialog.instruction.btn_text')}
          </Button>
        )}
      </div>

      {isMobile && (
        <Instruction
          title={t('payment_dialog.instruction.title')}
          className={st.instruction}
          items={[
            t('payment_dialog.instruction.rule_1'),
            t('payment_dialog.instruction.rule_2'),
            t('payment_dialog.instruction.rule_3'),
          ]}
        />
      )}
    </Dialog>
  );
};
