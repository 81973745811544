import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDemoGame, useLogin } from 'lib/hooks';
import { useDialogClose, useDialogsAdd } from 'lib/contexts';

import {
  Button,
  DemoWinDialog,
  Input,
  InputPassword,
  PseudoLink,
  RecoveryDialog,
  RegistrationDialog,
  ResultDialog,
  Text,
} from 'components';

import { Dialog } from '../Dialog';

import st from './LoginDialog.module.less';

export const LoginDialog = () => {
  const { t } = useTranslation();

  const { errors, login, password, handleChange, handleLogin } = useLogin();

  const { isDemoGamesPlayed } = useDemoGame();
  const addDialog = useDialogsAdd();
  const closeDialog = useDialogClose();

  const handleLoginClick = useCallback(() => {
    handleLogin({
      onSuccess: () => {
        closeDialog();
        addDialog(<ResultDialog title={t('login_page.success')} />);
      },
      onUnknownError: () => addDialog(<ResultDialog error title={<Trans i18nKey="login_page.error" />} />),
    });
  }, [addDialog, closeDialog, handleLogin, t]);

  const handleRestoreClick = useCallback(() => {
    closeDialog();
    addDialog(<RecoveryDialog />);
  }, [addDialog, closeDialog]);

  const handleRegistration = useCallback(() => {
    closeDialog();
    addDialog(<RegistrationDialog />);
  }, [addDialog, closeDialog]);

  const handleClose = useCallback(() => {
    if (isDemoGamesPlayed) {
      addDialog(<DemoWinDialog />);
    }
  }, [isDemoGamesPlayed, addDialog]);

  return (
    <Dialog onClose={handleClose}>
      <Text className={st.title} size="xl" weight="w600">
        {t('login_page.modal.title')}
      </Text>

      <p>
        {t('login_page.modal.description')}
      </p>

      <form className={st.form}>
        <Input
          modal
          errorMessage={errors.login}
          autoComplete="username"
          name="login"
          placeholder={t('login')}
          value={login}
          onChange={handleChange}
        />

        <InputPassword
          modal
          errorMessage={errors.password}
          autoComplete="current-password"
          name="password"
          placeholder={t('password')}
          value={password}
          onChange={handleChange}
        />

        <div className={st.recoveryRow}>
          <PseudoLink color="grayBlue2" weight="w500" size="s" onClick={handleRestoreClick}>
            {t('login_page.forgot_password')}
          </PseudoLink>
        </div>
      </form>

      <div className={st.buttons}>
        <Button fluid onClick={handleLoginClick}>
          {t('login_page.login_btn')}
        </Button>

        <Button fluid theme="secondaryModal" onClick={handleRegistration}>
          {t('login_page.modal.registration_btn')}
        </Button>
      </div>
    </Dialog>
  );
};
