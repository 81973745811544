import { useCallback } from 'react';

import useEventListener from './useEventListener';

export const useOnEscapePress = (onClose: () => void): void => {
  const handleKeyUp = useCallback(({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEventListener('keyup', handleKeyUp);
};
