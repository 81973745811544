import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Button } from 'components';

import st from './FormButtons.module.less';

interface IProps {
  submitDisabled?: boolean;
  className?: string;
  href?: string;
  cancelHref?: string;
  buttonText?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const FormButtons = ({
  submitDisabled,
  buttonText,
  className,
  href,
  cancelHref,
  children,
  onSubmit,
  onCancel,
}: PropsWithChildren<IProps>) => {
  const { t } = useTranslation();

  return (
    <div className={cn(st.formButtons, className)}>
      {children || (
        <>
          <Button fluid theme="secondary" className={st.cancelBtn} href={cancelHref} onClick={onCancel}>
            {t('cancel')}
          </Button>

          <Button fluid disabled={submitDisabled} href={href} onClick={onSubmit}>
            {buttonText || t('continue')}
          </Button>
        </>
      )}
    </div>
  );
};
