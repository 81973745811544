import { SyntheticEvent, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { changeUserPassword } from 'api';
import { useChangePasswordStore } from 'store';
import { useDialogClose, useDialogsAdd } from 'lib/contexts';

import { Button, InputPassword, LoginDialog, RecoveryDialog, ResultDialog, Text } from 'components';

import { Dialog } from '../Dialog';

import st from './ChangePasswordDialog.module.less';

export const ChangePasswordDialog = () => {
  const passphrase = useChangePasswordStore.use.passphrase();
  const newPassword = useChangePasswordStore.use.newPassword();

  const { setNewPassword } = useChangePasswordStore.getState();

  const { t } = useTranslation();
  const addDialog = useDialogsAdd();
  const closeDialog = useDialogClose();

  const [error, setError] = useState('');

  const handleChange = useCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    setNewPassword(value);
  }, [setNewPassword]);

  const handleAddLoginDialog = useCallback(() => addDialog(<LoginDialog />), [addDialog]);

  const handleAddRecoveryDialog = useCallback(() => addDialog(<RecoveryDialog />), [addDialog]);

  const handleSubmit = useCallback(() => {
    changeUserPassword({ passphrase, newPassword })
      .then(() => {
        closeDialog();
        addDialog(<ResultDialog title={t('password_recovery_page.success')} onClick={handleAddLoginDialog} />);
      })
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            passphrase: passphraseErrors = [],
            newPassword: newPasswordErrors = [],
          } = {},
        } = data;

        if (passphraseErrors.length) {
          closeDialog();

          addDialog(
            <ResultDialog
              error
              title={passphraseErrors[0]}
              onClick={handleAddRecoveryDialog}
            />,
          );

          return false;
        }

        if (newPasswordErrors.length) {
          setError(newPasswordErrors[0]);
          return false;
        }

        addDialog(<ResultDialog error title={<Trans i18nKey="error_message" />} />);
      });
  }, [passphrase, newPassword, closeDialog, addDialog, t, handleAddLoginDialog, handleAddRecoveryDialog]);

  const handleCancelClick = useCallback(() => {
    closeDialog();
    addDialog(<RecoveryDialog />);
  }, [addDialog, closeDialog]);

  return (
    <Dialog>
      <Text className={st.title} size="xl" weight="w600">
        {t('password_recovery_page.title')}
      </Text>

      <InputPassword
        modal
        errorMessage={error}
        autoComplete="new-password"
        name="password"
        placeholder={t('password_recovery_page.new_password')}
        value={newPassword}
        onChange={handleChange}
      />

      <div className={st.buttons}>
        <Button disabled={!newPassword} fluid onClick={handleSubmit}>
          {t('save')}
        </Button>

        <Button fluid theme="secondaryModal" onClick={handleCancelClick}>
          {t('cancel')}
        </Button>
      </div>
    </Dialog>
  );
};
