import { ReactNode } from 'react';

import { DialogsContainer } from 'components';
import { DialogsStateProvider } from '../contexts';

interface IProps {
  children?: ReactNode;
}

export const DialogsProvider = ({ children }: IProps) => (
  <DialogsStateProvider>
    <DialogsContainer />
    {children}
  </DialogsStateProvider>
);
