import { ELanguages } from '../types';

export const LANGUAGES: Record<ELanguages, { name: string, flag: string }> = {
  [ELanguages.ES]: {
    name: 'Español',
    flag: '🇪🇸',
  },
  [ELanguages.EN]: {
    name: 'English',
    flag: '🇺🇸',
  },
  [ELanguages.RU]: {
    name: 'Русский',
    flag: '🇷🇺',
  },
};
