import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ROUTES } from 'lib/constants';

import st from './AuthTabs.module.less';

interface IProps {
  className?: string;
}

export const AuthTabs = ({ className }: IProps) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  return (
    <div className={cn(st.authTabs, className)}>
      <Link className={cn(st.link, { [st.active]: pathname === ROUTES.LOGIN })} to={ROUTES.LOGIN}>
        {t('login_page.login_tab')}
      </Link>

      <Link className={cn(st.link, { [st.active]: pathname === ROUTES.REGISTRATION })} to={ROUTES.REGISTRATION}>
        {t('registration')}
      </Link>
    </div>
  );
};
