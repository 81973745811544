import { useQuery } from 'react-query';

import { api, getUrl } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { IGameDTOGetAllDTO } from '../../contracts';

interface IUserPlayedGames {
  page: number;
  pageSize: number;
}

const fetchUserPlayedGames = ({
  page = 1,
  pageSize = 100,
}: IUserPlayedGames): Promise<IGameDTOGetAllDTO> => (
  api.get(getUrl('/api/games/played', { query: { page: String(page), pageSize: String(pageSize) } }))
);

type TQueryFn = typeof fetchUserPlayedGames;

export const useUserPlayedGames = <D = ExtractFnReturnType<TQueryFn>>(
  search: IUserPlayedGames,
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['user-played-games', search],
  queryFn: () => fetchUserPlayedGames(search),
});
