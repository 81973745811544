import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconArrowBack, iconArrowRight, iconWallet } from 'assets';
import { ECashierTabs } from 'lib/types';
import { useCurrentUserQuery } from 'api';
import { useDialogsAdd } from 'lib/contexts';
import { CHIP_PRICE, ROUTES } from 'lib/constants';
import { getUrl, numberAddSpace, numberToChips, numberToMoney } from 'lib/utils';

import { Button, DialogPayment, Icon, Spinner, Text, Title } from 'components';

import st from './PersonalAccount.module.less';

interface IProps {
  className?: string;
}

export const PersonalAccountHeader = ({ className }: IProps) => {
  const { t } = useTranslation();

  const addDialog = useDialogsAdd();

  const { isLoading, data: { balance = 0, login } = {} } = useCurrentUserQuery();

  const handleClick = useCallback(() => addDialog(<DialogPayment />), [addDialog]);

  if (isLoading) {
    return (
      <div className={cn(st.spinnerContainer, className)}>
        <Spinner centered />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={st.info}>
        <Text className={st.profileText} size="s">
          {t('personal_account.profile')}
        </Text>

        <Link to={ROUTES.PROFILE} className={st.profileLink}>
          <Text weight="w500">
            {login}
          </Text>

          <Icon className={st.iconArrowBack} icon={iconArrowBack} />
          <Icon className={st.iconArrowRight} icon={iconArrowRight} />
        </Link>
      </div>

      <div className={st.mainContent}>
        <Title as="p" className={st.balance} size="h3">
          <Icon className={st.iconWallet} icon={iconWallet} />
          {numberToMoney(balance)}
        </Title>

        <Text className={st.chips} color="grayBlue" size="s">
          {t('personal_account.chips_available')}
          &nbsp;–&nbsp;
          <Text as="span" color="white" weight="w700">
            {numberAddSpace(numberToChips(balance))}
          </Text>
        </Text>

        <Text uppercase rubik size="s" weight="w500" color="grayBlue">
          {`${numberToMoney(CHIP_PRICE)} = 1 ${t('chip')}`}
        </Text>

        <div className={st.buttons}>
          <Button fluid theme="secondary" href={getUrl(ROUTES.CASHIER, { query: { view: 'withdraw' } })}>
            <Trans i18nKey="personal_account.withdraw_btn" components={{ span: <span className={st.moneyText} /> }} />
          </Button>

          <Button
            fluid
            className={st.cashierBtn}
            href={getUrl(ROUTES.CASHIER, { query: { view: ECashierTabs.DEPOSIT } })}
            onClick={handleClick}
          >
            {t('cashier')}
          </Button>
        </div>
      </div>
    </div>
  );
};
