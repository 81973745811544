import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import cn from 'classnames';

import { iconArrowAccordion } from 'assets';

import { Icon, Text } from 'components';

import st from './Accordion.module.less';

interface IProps {
  initialOpen?: boolean;
  buttonClassName?: string;
  className?: string;
  contentClassName?: string;
  iconSize?: number;
  title: ReactNode;
}

export const Accordion = ({
  initialOpen,
  buttonClassName,
  children,
  className,
  contentClassName,
  iconSize,
  title,
}: PropsWithChildren<IProps>) => {
  const [isOpened, setOpen] = useState(initialOpen);

  const handleClick = useCallback(() => setOpen(!isOpened), [isOpened]);

  return (
    <div className={cn(className, { [st.opened]: isOpened })}>
      <button type="button" className={cn(st.btn, { [st.isOpened]: isOpened }, buttonClassName)} onClick={handleClick}>
        <Text className={st.title} weight="w500">
          {title}
        </Text>

        <Icon size={iconSize} icon={iconArrowAccordion} className={st.iconArrowAccordion} />
      </button>

      <div className={cn(st.content, contentClassName)}>
        {children}
      </div>
    </div>
  );
};
