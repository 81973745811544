import { Fragment, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { withdrawCrypto } from 'api';
import { numberToMoney } from 'lib/utils';
import { useDialogsAdd } from 'lib/contexts';
import { MIN_WITHDRAW_AMOUNT, ROUTES } from 'lib/constants';

import { Button, Input, ResultDialog, Spinner, Text, Tooltip } from 'components';

import { useCashierStore } from '../store';
import MinAmount from '../MinAmount';
import { CurrencyDropdown } from '../CurrencyDropdown';

import { useWithdraw } from './useWithdraw';

import st from './Withdraw.module.less';

interface IProps {
  className?: string;
}

export const Withdraw = ({ className }: IProps) => {
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { t } = useTranslation();

  const {
    isConversionRatesLoading,
    isLoadingWithdrawCrypto,
    isSuccess,
    amounts,
    amountUsd,
    animated,
    commissions,
    errors,
    addressTo,
    setSuccess,
    setErrors,
    setLoadingWithdrawCrypto,
    handleInputChange,
  } = useWithdraw();

  const addDialog = useDialogsAdd();

  const handleClick = useCallback(() => {
    setLoadingWithdrawCrypto(true);

    withdrawCrypto({
      currency: currentCurrency,
      amountUsd: Number(amountUsd),
      addressTo,
    })
      .then(() => setSuccess(true))
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            addressTo: addressToErrors = [],
            'Validation Error': validationError = [],
          } = {},
        } = data;

        if (addressToErrors.length) {
          setErrors({ ...errors, addressTo: addressToErrors[0] });
          return false;
        }

        addDialog(
          <ResultDialog
            error
            title={(
              <Trans
                i18nKey={validationError?.[0] === 'User has insufficient balance'
                  ? 'cashier_page.withdraw.error_message'
                  : 'error_message'}
              />
            )}
          />,
        );
      })
      .finally(() => setLoadingWithdrawCrypto(false));
  }, [addDialog, addressTo, amountUsd, currentCurrency, errors, setErrors, setLoadingWithdrawCrypto, setSuccess]);

  const getContent = () => {
    if (isSuccess) {
      return (
        <div>
          <Text color="grayBlue" size="s">
            {t('cashier_page.withdraw.has_been_sent')}
          </Text>

          <Button fluid className={cn(st.btn, st.playBtn)} href={ROUTES.GAME}>
            {t('cashier_page.withdraw.play_btn')}
          </Button>
        </div>
      );
    }

    return (
      <>
        <CurrencyDropdown className={st.currencyDropdown} />

        <form className={st.form}>
          <div className={st.amountUsdContainer}>
            <Input
              containerClassName={st.input}
              errorMessage={errors.amountUsd ? String(errors.amountUsd) : ''}
              name="amountUsd"
              value={Number(amountUsd) ? String(amountUsd) : ''}
              placeholder={t('cashier_page.withdraw.amount_usd')}
              type="number"
              onChange={handleInputChange}
            />

            <Spinner isLoading={isConversionRatesLoading} className={st.spinner} />

            <Text className={st.description} size="s" color="grayBlue2" weight="w500">
              <Trans
                i18nKey="cashier_page.withdraw.withdraw_amount"
                components={{ min: <MinAmount animated={animated} /> }}
                values={{ value: numberToMoney(MIN_WITHDRAW_AMOUNT) }}
              />
            </Text>
          </div>

          <div>
            <Input
              readOnly
              containerClassName={st.input}
              name="totalAmount"
              value={amounts ? String(amounts[currentCurrency]) : ''}
              placeholder={t('cashier_page.withdraw.total_amount')}
            />

            <Text as="div" className={st.description} size="s" color="grayBlue2" weight="w500">
              <div className={st.tooltipContainer}>
                {t('cashier_page.withdraw.transaction_fee')}
                {' '}
                <Tooltip className={st.tooltip}>
                  {t('cashier_page.withdraw.tooltip')}
                </Tooltip>
              </div>
              :
              {' '}
              {numberToMoney(commissions ? String(commissions?.[currentCurrency]) : 0)}
            </Text>
          </div>

          <Input
            containerClassName={st.input}
            errorMessage={errors.addressTo}
            name="addressTo"
            value={addressTo}
            placeholder={t('cashier_page.withdraw.address_to')}
            onChange={handleInputChange}
          />

          <Text className={st.text} color="grayBlue" weight="w500" mobileSize="xs">
            <Trans i18nKey="cashier_page.withdraw.description" components={{ span: <span className={st.db} /> }} />
          </Text>

          <div className={st.mobileDescription}>
            <Text className={st.mobileDescriptionItem} color="grayBlue" weight="w500">
              <Trans
                i18nKey="cashier_page.withdraw.withdraw_amount"
                components={{ min: <MinAmount animated={animated} /> }}
                values={{ value: numberToMoney(MIN_WITHDRAW_AMOUNT) }}
              />
            </Text>

            <Text as="div" className={st.mobileDescriptionItem} color="grayBlue" weight="w500">
              {t('cashier_page.withdraw.transaction_fee')}
              <Tooltip className={st.mobileTooltip}>
                {t('cashier_page.withdraw.tooltip')}
              </Tooltip>

              <span>
                {numberToMoney(4)}
              </span>
            </Text>
          </div>

          <Button
            fluid
            disabled={amountUsd < MIN_WITHDRAW_AMOUNT || !addressTo}
            className={st.btn}
            onClick={handleClick}
          >
            {t('cashier_page.withdraw.request_btn')}
          </Button>

          <Text className={st.mobileText} color="grayBlue2" weight="w500" mobileSize="xs">
            <Trans i18nKey="cashier_page.withdraw.description" components={{ span: <span /> }} />
          </Text>
        </form>
      </>
    );
  };

  return (
    <div className={cn(st.withdraw, className)}>
      {getContent()}

      <Spinner isLoading={isLoadingWithdrawCrypto} absolute withBackdropBlur className={st.globalSpinner} />
    </div>
  );
};
