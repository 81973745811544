import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { iconSupport } from 'assets';

import { Icon, Text } from 'components';

import st from './Support.module.less';

interface IProps {
  className?: string;
}

export const Support = ({ className }: IProps) => {
  const { t } = useTranslation();

  return (
    <Link
      to="mailto:info@lotopoly.net"
      className={cn(st.support, className)}
    >
      <Text as="span" className={st.title} weight="w500" size="xs">
        <Icon icon={iconSupport} size={12} />
        {t('support')}
        &nbsp;
        24/7
      </Text>

      <Text as="span" size="xs">
        Lotopoly
        {' '}
        {dayjs().year()}
      </Text>
    </Link>
  );
};
