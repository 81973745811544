import { useMemo } from 'react';
import cn from 'classnames';

import { useGame } from 'lib/hooks';

import { Spinner } from 'components';

import { GameButton } from '../GameButton';

import { GAME_FIELD } from './config';

import st from './GameField.module.less';

interface IProps {
  className?: string;
}

export const GameField = ({ className }: IProps) => {
  const { isPlaying, status } = useGame();

  const classNames = cn(
    st.gameField,
    {
      [st.disabled]: status,
      [st.loading]: isPlaying,
    },
    className,
  );

  const buttons = useMemo(() => GAME_FIELD.map((_, index) => {
    const num = index + 1;

    return <GameButton key={num} value={num} />;
  }), []);

  return (
    <div className={classNames}>
      <div className={st.buttons}>
        {buttons}

        <Spinner absolute withBackdropBlur isLoading={isPlaying} size={32} />
      </div>
    </div>
  );
};
