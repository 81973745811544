import { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useRedirectToGame } from 'lib/hooks';
import { useChangePasswordStore } from 'store';
import { PASSPHRASE_ERROR_MESSAGE, ROUTES, VALID_PASSPHRASE_LENGTH } from 'lib/constants';

import { FormButtons, InfoMessage, InnerPage, InnerPageHeader, Input } from 'components';

import st from './Recovery.module.less';

export const Recovery = () => {
  const passphrase = useChangePasswordStore.use.passphrase();

  const { setPassphrase } = useChangePasswordStore.getState();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [error, setError] = useState('');

  const handleChange = useCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    setPassphrase(value);
  }, [setPassphrase]);

  const handleClick = useCallback(() => {
    if (passphrase.length < VALID_PASSPHRASE_LENGTH) {
      setError(PASSPHRASE_ERROR_MESSAGE);
      return false;
    }

    navigate(ROUTES.CHANGE_PASSWORD);
  }, [navigate, passphrase.length]);

  useRedirectToGame();

  return (
    <InnerPage backLink={ROUTES.LOGIN}>
      <InnerPageHeader className={st.header} title={t('password_recovery_page.title')} />

      <InfoMessage>
        <p>
          {t('password_recovery_page.description')}
        </p>
      </InfoMessage>

      <form className={st.form}>
        <div className={st.row}>
          <Input
            errorMessage={error}
            autoComplete="off"
            name="passphrase"
            placeholder={t('password_recovery_page.placeholder')}
            value={passphrase}
            onChange={handleChange}
          />
        </div>
      </form>

      <FormButtons
        cancelHref={ROUTES.LOGIN}
        submitDisabled={!passphrase}
        onSubmit={handleClick}
      />
    </InnerPage>
  );
};
