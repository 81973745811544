import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { ROUTES } from 'lib/constants';
import { changeUserPassword } from 'api';
import { useDialogsAdd } from 'lib/contexts';
import { useRedirectToGame } from 'lib/hooks';
import { useChangePasswordStore } from 'store';

import { FormButtons, InfoMessage, InnerPage, InnerPageHeader, InputPassword, ResultDialog } from 'components';

import st from './ChangePassword.module.less';

export const ChangePassword = () => {
  const passphrase = useChangePasswordStore.use.passphrase();
  const newPassword = useChangePasswordStore.use.newPassword();

  const { setNewPassword } = useChangePasswordStore.getState();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const addDialog = useDialogsAdd();

  const handleChange = useCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    setNewPassword(value);
  }, [setNewPassword]);

  const handleSubmit = useCallback(() => {
    changeUserPassword({ passphrase, newPassword })
      .then(() => {
        addDialog(<ResultDialog title={t('password_recovery_page.success')} href={ROUTES.LOGIN} />);
      })
      .catch(({ response: { data = {} } } = {}) => {
        const {
          errors: {
            passphrase: passphraseErrors = [],
            newPassword: newPasswordErrors = [],
          } = {},
        } = data;

        if (passphraseErrors.length) {
          addDialog(<ResultDialog error title={passphraseErrors[0]} href={ROUTES.RECOVERY} />);
          return false;
        }

        if (newPasswordErrors.length) {
          addDialog(<ResultDialog error title={newPasswordErrors[0]} />);
          return false;
        }

        addDialog(<ResultDialog error title={<Trans i18nKey="error_message" />} />);
      });
  }, [addDialog, newPassword, passphrase, t]);

  useRedirectToGame();

  useEffect(() => {
    if (!passphrase) {
      navigate(ROUTES.RECOVERY);
    }
  }, [navigate, passphrase]);

  return (
    <InnerPage backLink={ROUTES.RECOVERY}>
      <InnerPageHeader className={st.header} title={t('password_recovery_page.title')} />

      <InfoMessage>
        <p>
          {t('password_recovery_page.new_password_description')}
        </p>
      </InfoMessage>

      <form className={st.form}>
        <InputPassword
          autoComplete="new-password"
          name="password"
          placeholder={t('password_recovery_page.new_password')}
          value={newPassword}
          onChange={handleChange}
        />
      </form>

      <FormButtons
        buttonText={t('save')}
        cancelHref={ROUTES.RECOVERY}
        submitDisabled={!(newPassword && passphrase)}
        onSubmit={handleSubmit}
      />
    </InnerPage>
  );
};
