import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { useLockBodyScroll } from 'lib/hooks';

interface IProps {
  isShown?: boolean;
  withLockBodyScroll?: boolean;
}

export const Portal = ({ isShown, withLockBodyScroll, children }: PropsWithChildren<IProps>) => {
  useLockBodyScroll(isShown && withLockBodyScroll);

  return createPortal(children, document.body);
};
