import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthStore } from 'store';
import { ROUTES } from 'lib/constants';

interface IProps {
  component: ReactElement;
}

export const PrivateRoute = ({ component: Component }: IProps) => {
  const isAuth = useAuthStore.use.isAuth();

  if (!isAuth) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return Component;
};
