import { useTranslation } from 'react-i18next';

import { InfoMessage } from 'components';

export const AuthWarning = () => {
  const { t } = useTranslation();

  return (
    <InfoMessage>
      <p>{t('login_page.warning')}</p>
    </InfoMessage>
  );
};
