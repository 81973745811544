import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createSelectors } from 'lib/common';
import { IRecoverUserDTO } from '../api';

interface IChangePasswordStore extends IRecoverUserDTO {
  setPassphrase: (passphrase: string) => void;
  setNewPassword: (newPassword: string) => void;
}

const useChangePasswordBase = create<IChangePasswordStore>()(
  immer((set) => ({
    passphrase: '',
    newPassword: '',

    setPassphrase: (passphrase) => set((store) => ({
      ...store,
      passphrase,
    })),

    setNewPassword: (newPassword) => set((store) => ({
      ...store,
      newPassword,
    })),
  })),
);

export const useChangePasswordStore = createSelectors(useChangePasswordBase);
