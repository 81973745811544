import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconArrowDropdown, iconGlobe } from 'assets';
import { ELanguages } from 'lib/types';
import { LANGUAGES } from 'lib/common';
import { useToggleOpen } from 'lib/hooks';

import { Icon } from '../../layout';

import st from './LanguageSwitcher.module.less';

interface IProps {
  className?: string;
  position?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
}

export const LanguageSwitcher = ({ className, position = 'bottom-center' }: IProps) => {
  const { i18n: { changeLanguage, resolvedLanguage } } = useTranslation();

  const { isOpen, ref, close: closeDropdown, toggleOpen } = useToggleOpen({ lockBody: false });

  const [verticalPosition, horizontalPosition] = position.split('-');

  const classNames = cn(
    st.languageSwitcher,
    {
      [st.opened]: isOpen,
    },
    st[verticalPosition],
    st[horizontalPosition],
    className,
  );

  const handleOptionClick = useCallback((language: string) => () => {
    changeLanguage(language).then(closeDropdown);
  }, [changeLanguage, closeDropdown]);

  const options = useMemo(() => Object.entries(LANGUAGES).map(([language, { name, flag }]) => (
    <button
      key={language}
      className={cn(st.btn, st.option, { [st.active]: resolvedLanguage === language })}
      type="button"
      onClick={handleOptionClick(language)}
    >
      {flag}
      &nbsp;
      {name}
    </button>
  )), [resolvedLanguage, handleOptionClick]);

  return (
    <div className={classNames} ref={ref}>
      <button className={cn(st.btn, st.current)} type="button" onClick={toggleOpen}>
        <Icon className={st.iconGlobe} icon={iconGlobe} size={20} />
        {LANGUAGES[resolvedLanguage as ELanguages].name}
        <Icon className={st.iconArrowDropdown} icon={iconArrowDropdown} size={16} />
      </button>

      <div className={st.dropdown}>
        {options}
      </div>
    </div>
  );
};
