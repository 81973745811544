import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconArrowDropdown, iconUserCircle } from 'assets';
import { useGame } from 'lib/hooks';
import { useAuthStore } from 'store';
import { ROUTES } from 'lib/constants';
import { useCurrentUserQuery } from 'api';
import { numberToMoney } from 'lib/utils';
import { useDialogsAdd } from 'lib/contexts';

import { ButtonRefresh, Icon, LoginDialog, Logo, Spinner, Text, Title } from 'components';

import st from './GameHeader.module.less';

interface IProps {
  className?: string;
}

export const GameHeader = ({ className }: IProps) => {
  const isAuth = useAuthStore.use.isAuth();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const addDialog = useDialogsAdd();

  const {
    isDemo,
    isFetchingNewGame,
    gameNumber,
    checkStringHash,
    handleRefreshGame,
  } = useGame();

  const { isFetching: isFetchingCurrentUser, data: { balance = 0 } = {} } = useCurrentUserQuery({
    enabled: isAuth,
  });

  const isFetching = isFetchingNewGame || isFetchingCurrentUser;

  const handleProfileClick = useCallback(() => {
    if (isAuth) {
      navigate(ROUTES.PERSONAL_ACCOUNT);
    } else {
      addDialog(<LoginDialog />);
    }
  }, [isAuth, addDialog, navigate]);

  return (
    <div className={cn(st.gameHeader, className)}>
      {isFetching && <Spinner className={st.spinner} absolute withBackdropBlur />}

      <div className={st.mobileHeader}>
        <Logo className={st.logo} />

        <button type="button" className={st.mobileBalanceBtn} onClick={handleProfileClick}>
          <Icon className={st.iconUserCircle} icon={iconUserCircle} size={16} />

          <Text size="s" weight="w800">
            {numberToMoney(isAuth ? balance.toFixed(2) : '∞')}
          </Text>

          <Icon icon={iconArrowDropdown} size={16} />
        </button>
      </div>

      <div className={st.titleContainer}>
        <Title as="h1" className={st.title} size="h2">
          {t('roulette')}
          <span>
            {gameNumber}
          </span>
        </Title>

        {!isDemo && <ButtonRefresh className={st.buttonRefresh} onRefresh={handleRefreshGame} />}
      </div>

      <Text className={st.uniqueCode} size="s">
        {t('game.unique_code')}
      </Text>

      <Text rubik className={st.code} size="s" weight="w600">
        {checkStringHash}
      </Text>
    </div>
  );
};
