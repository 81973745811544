import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { iconLogo, iconLogoFull } from 'assets';
import { ROUTES } from 'lib/constants';
import { useCheckHome } from 'lib/hooks';
import { useSiteConfigStore } from 'store';

import { Icon } from 'components';

import st from './Logo.module.less';

interface IProps {
  small?: boolean;
  className?: string;
}

export const Logo = ({ small: isSmall, className }: IProps) => {
  const isDesktop = useSiteConfigStore.use.isDesktop();

  const isHomePage = useCheckHome();

  const logoIcon = useMemo(() => {
    if (isSmall) {
      return iconLogo;
    }

    return isDesktop ? iconLogoFull : iconLogo;
  }, [isDesktop, isSmall]);

  const logo = (
    <Icon
      icon={logoIcon}
      className={cn(st.logo, { [st.small]: isSmall }, className)}
      shapeRendering="geometricPrecision"
    />
  );

  if (!isHomePage) {
    return (
      <Link className={st.link} to={ROUTES.GAME}>
        {logo}
      </Link>
    );
  }

  return logo;
};
