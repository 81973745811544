import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { numberToMoney } from 'lib/utils';
import { useUserPlayedGames } from 'api';
import { Pagination, Spinner, Table, TableCell, TableRow } from 'components';

import NoData from '../NoData';

import { TABLE_HEADER } from './config';

import st from './GameHistory.module.less';

interface IProps {
  className?: string;
}

export const GameHistory = ({ className }: IProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 30,
  });

  const { pageSize, page } = pagination;

  const {
    isLoading,
    data: {
      items = [],
      totalItems = 0,
    } = {},
  } = useUserPlayedGames(pagination);

  const header = useMemo(() => TABLE_HEADER.map((row) => (
    <TableCell type="th" key={row}>
      {t(row)}
    </TableCell>
  )), [t]);

  const content = useMemo(() => items.map(({ id, status, number, winningAmount }) => (
    <TableRow key={id}>
      <TableCell mobileSize="s">
        {number}
      </TableCell>

      <TableCell mobileSize="s" className={st[status.toLowerCase()]}>
        {t(`game_statuses.${status}`)}
      </TableCell>

      <TableCell mobileSize="s">
        {numberToMoney(winningAmount || 0)}
      </TableCell>
    </TableRow>
  )), [items, t]);

  const handlePagination = useCallback((_page: number) => setPagination({
    ...pagination,
    page: _page + 1,
  }), [pagination]);

  if (isLoading) {
    return (
      <div className={st.spinnerContainer}>
        <Spinner centered />
      </div>
    );
  }

  return (
    <Table className={className}>
      {items.length ? (
        <>
          <TableRow>
            {header}
          </TableRow>

          <TableRow>
            <TableCell color="grayBlue">
              {t('personal_account.game_history.wait')}
            </TableCell>
          </TableRow>

          {content}

          <Pagination
            centered
            className={st.pagination}
            limit={1}
            offset={page - 1}
            total={totalItems / pageSize}
            onClick={handlePagination}
          />
        </>
      ) : (
        <NoData />
      )}
    </Table>
  );
};
