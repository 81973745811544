import { EPSILON, SCROLL_DURATION } from 'lib/constants';

interface IScrollToCoordinate {
  coordinate: number;
  duration?: number;
}

const scrollToCoordinate = ({ coordinate, duration = SCROLL_DURATION }: IScrollToCoordinate): void => {
  const initialY = window.scrollY;
  const baseY = (initialY + coordinate) * 0.5;
  const difference = initialY - baseY;
  const startTime = performance.now();

  function step() {
    let normalizedTime = (performance.now() - startTime + EPSILON) / duration;

    if (normalizedTime > 1) {
      normalizedTime = 1;
    }

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));

    if (normalizedTime < 1) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
};

export default scrollToCoordinate;
