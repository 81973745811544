import { useTranslation } from 'react-i18next';

import { ROUTES } from 'lib/constants';

import { Accordion, InnerPage, InnerPageHeader, LanguageSwitcher } from 'components';

import { Support } from './Support';
import { GameHistory } from './GameHistory';
import { Transactions } from './Transactions';
import { PersonalAccountHeader } from './Header';

import st from './PersonalAccount.module.less';

export const PersonalAccount = () => {
  const { t } = useTranslation();

  return (
    <InnerPage contentClassName={st.personalAccount} backLink={ROUTES.GAME}>
      <InnerPageHeader className={st.header} backLink={ROUTES.GAME} title={t('personal_account.title')}>
        <LanguageSwitcher className={st.switcher} />
      </InnerPageHeader>

      <PersonalAccountHeader />

      <Accordion initialOpen title={t('personal_account.game_history.title')}>
        <GameHistory />
      </Accordion>

      <Accordion initialOpen title={t('personal_account.transactions.title')}>
        <Transactions />
      </Accordion>

      <Support className={st.support} />
    </InnerPage>
  );
};
