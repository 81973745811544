import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createSelectors } from '../lib/common';
import { IGetAccessTokenDTO } from '../api';

interface IAuthStore {
  isAuth: boolean;
  setAuthorized: (isAuth: boolean) => void;

  authToken: string | null;
  setAuthToken: (authToken: string | null) => void;

  credentials: IGetAccessTokenDTO;
  setCredentials: (credentials: IGetAccessTokenDTO) => void;
}

const useAuthStoreBase = create<IAuthStore>()(
  immer((set) => ({
    isAuth: false,
    setAuthorized: (isAuth) => set((state) => ({ ...state, isAuth })),

    authToken: null,
    setAuthToken: (authToken) => set((state) => ({ ...state, authToken })),

    credentials: {
      login: '',
      password: '',
    },
    setCredentials: (credentials) => set((state) => ({ ...state, credentials })),
  })),
);

export const useAuthStore = createSelectors(useAuthStoreBase);
