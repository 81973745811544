import { useCallback } from 'react';
import { parseCookies } from 'nookies';

import { useGameDemoStore, useGameStore } from 'store';
import { GameStatus, useNewDemoGameQuery, useNewGameQuery, usePlayGameQuery } from 'api';

import { useCookies } from '../common';

export const useGame = () => {
  const gameId = useGameStore.use.gameId();
  const gameNumber = useGameStore.use.gameNumber();
  const checkStringHash = useGameStore.use.checkStringHash();
  const selectedNumbers = useGameStore.use.selectedNumbers();

  const { resetGame, setSelectedNumbers } = useGameStore.getState();

  const isDemo = useGameDemoStore.use.isDemo();
  const isDemoPlayed = useGameDemoStore.use.isDemoPlayed();
  const demoCheckString = useGameDemoStore.use.demoCheckString();
  const demoWinNumber = useGameDemoStore.use.demoWinNumber();
  const demoGameNumber = useGameDemoStore.use.demoGameNumber();
  const demoCheckStringHash = useGameDemoStore.use.demoCheckStringHash();
  const demoSelectedNumbers = useGameDemoStore.use.demoSelectedNumbers();

  const { setDemoPlayed, resetDemoGame, setDemoSelectedNumbers } = useGameDemoStore.getState();

  const { isFetching: isFetchingNewGame, refetch: startNewGame } = useNewGameQuery({
    enabled: false,
  });

  const { isFetching: isFetchingNewDemoGame, refetch: startNewDemoGame } = useNewDemoGameQuery({
    enabled: false,
  });

  const {
    isLoading: isPlaying,
    data: { status, checkString, winNumber = 0 } = {},
    refetch: playGame,
  } = usePlayGameQuery(gameId, { selectedNumbers }, {
    enabled: false,
  });

  const { setLotopolyCookie } = useCookies();
  const { DEMO_GAME_COUNT = '0' } = parseCookies();

  const demoWinNumberTruncated = isDemoPlayed ? Math.trunc(demoWinNumber) : 0;
  const isDemoWin = isDemoPlayed && demoSelectedNumbers.includes(demoWinNumberTruncated);
  const isDemoLoss = isDemoPlayed && !isDemoWin;

  const getDemoStatus = useCallback(() => {
    if (isDemoPlayed) {
      return isDemoWin ? GameStatus.Win : GameStatus.Loss;
    }

    return undefined;
  }, [isDemoPlayed, isDemoWin]);

  const handleRefreshGame = useCallback(() => {
    resetGame();
    startNewGame();
  }, [resetGame, startNewGame]);

  const handleRefreshDemoGame = useCallback(() => {
    resetDemoGame();
    startNewDemoGame();
  }, [resetDemoGame, startNewDemoGame]);

  const playDemoGame = useCallback(() => {
    setLotopolyCookie('DEMO_GAME_COUNT', String(Number(DEMO_GAME_COUNT) + 1));
    setDemoPlayed(true);
  }, [DEMO_GAME_COUNT, setDemoPlayed, setLotopolyCookie]);

  return {
    isWin: isDemo ? isDemoWin : status === GameStatus.Win,
    isLoss: isDemo ? isDemoLoss : status === GameStatus.Loss,
    isPlaying,
    isFetchingNewGame: isDemo ? isFetchingNewDemoGame : isFetchingNewGame,
    checkString: isDemo ? demoCheckString : checkString,
    checkStringHash: isDemo ? demoCheckStringHash : checkStringHash,
    gameNumber: isDemo ? demoGameNumber : gameNumber,
    selectedNumbers: isDemo ? demoSelectedNumbers : selectedNumbers,
    status: isDemo ? getDemoStatus() : status,
    winNumber: isDemo ? demoWinNumberTruncated : Math.trunc(winNumber),
    winNumberString: String(isDemo ? demoWinNumber : winNumber),
    setSelectedNumbers: isDemo ? setDemoSelectedNumbers : setSelectedNumbers,
    playGame,
    resetGame: isDemo ? resetDemoGame : resetGame,
    handleRefreshGame,

    isDemo,
    playDemoGame,
    handleRefreshDemoGame,
  };
};
