import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { iconQuestion } from 'assets';
import { Icon, Text } from 'components';

import st from './Tooltip.module.less';

interface IProps {
  className?: string;
}

export const Tooltip = ({ className, children }: PropsWithChildren<IProps>) => (
  <div className={cn(st.tooltip, className)}>
    <Icon icon={iconQuestion} size={16} />

    <Text as="div" color="white" className={st.tooltipContent}>
      {children}
    </Text>
  </div>
);
