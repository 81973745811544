import cn from 'classnames';

import { Win } from '../Win';
import { Loss } from '../Loss';
import { Rules } from '../Rules';
import { Control } from '../Control';
import { Balance } from '../Balance';
import { RandomButton } from '../RandomButton';

import st from './GameSidebar.module.less';

interface IProps {
  className?: string;
}

export const GameSidebar = ({ className }: IProps) => (
  <div className={cn(st.gameSidebar, className)}>
    <Balance />

    <Rules />
    <Win />
    <Loss />

    <Control />

    <RandomButton />
  </div>
);
