// TODO включить, когда (если) react-spring-bottom-sheet начнёт поддерживать StrictMode
// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './i18n';

import { App } from './App';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(<App />);
