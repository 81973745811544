import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconDice } from 'assets';
import { useGame } from 'lib/hooks';
import { useCurrentUserQuery } from 'api';
import { useAuthStore, useGameDemoStore, useGameStore } from 'store';
import { CHIP_PRICE, MAX_DEMO_GAME_CHIPS, MAX_GAME_CHIPS } from 'lib/constants';
import { getArrayOfRandomNumbers, getRandomInt } from 'lib/utils';

import { Button, Icon } from 'components';

import st from './RandomButton.module.less';

interface IProps {
  className?: string;
}

export const RandomButton = ({ className }: IProps) => {
  const isAuth = useAuthStore.use.isAuth();
  const isDemo = useGameDemoStore.use.isDemo();

  const { updateSelectedNumbers } = useGameStore.getState();
  const { updateDemoSelectedNumbers } = useGameDemoStore.getState();

  const { t } = useTranslation();

  const { status } = useGame();

  const { data: { balance = 0 } = {} } = useCurrentUserQuery({
    enabled: isAuth,
  });

  const handleRandom = useCallback(() => {
    const maxNumbersLength = Math.trunc(balance) / CHIP_PRICE;

    updateSelectedNumbers(getArrayOfRandomNumbers({
      from: 1,
      to: 100,
      size: maxNumbersLength > MAX_GAME_CHIPS ? MAX_GAME_CHIPS : maxNumbersLength,
      func: getRandomInt,
    }));
  }, [balance, updateSelectedNumbers]);

  const handleRandomDemo = useCallback(() => {
    updateDemoSelectedNumbers(getArrayOfRandomNumbers({
      from: 1,
      to: 100,
      size: MAX_DEMO_GAME_CHIPS,
      func: getRandomInt,
    }));
  }, [updateDemoSelectedNumbers]);

  if (isDemo) {
    return (
      <Button
        className={cn(st.randomButton, className)}
        disabled={!!status}
        theme="secondary"
        onClick={handleRandomDemo}
      >
        <Icon className={st.iconDice} icon={iconDice} />
        <span className={st.text}>
          {t('game.random_btn')}
        </span>
      </Button>
    );
  }

  if (status || balance < 5) {
    return null;
  }

  return (
    <Button className={cn(st.randomButton, className)} theme="secondary" onClick={handleRandom}>
      <Icon className={st.iconDice} icon={iconDice} />
      <span className={st.text}>
        {t('game.random_btn')}
      </span>
    </Button>
  );
};
