import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthStore } from 'store';
import { ROUTES } from 'lib/constants';

export const useRedirectToGame = () => {
  const isAuth = useAuthStore.use.isAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate(ROUTES.GAME);
    }
  }, [isAuth, navigate]);
};
