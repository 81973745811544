import { useQuery } from 'react-query';

import { api, getUrl } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { IWithdrawCryptoConversionRatesDTO } from '../../contracts';

const fetchConversionRates = (amountUsd: number): Promise<IWithdrawCryptoConversionRatesDTO> => (
  api.get(getUrl('/api/account/withdraw/crypto/conversion-rates', { query: { amountUsd: String(amountUsd) } }))
);

type TQueryFn = typeof fetchConversionRates;

export const useConversionRates = <D = ExtractFnReturnType<TQueryFn>>(
  amountUsd: number,
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['conversion-rates', amountUsd],
  queryFn: () => fetchConversionRates(amountUsd),
});
