import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from 'components';

interface IProps {
  className?: string;
}

const NoData = ({ className }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableRow className={className}>
      <TableCell color="grayBlue">
        {t('no_data')}
      </TableCell>
    </TableRow>
  );
};

export default NoData;
