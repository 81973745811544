import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Currency } from 'api';
import { createSelectors } from 'lib/common';

interface ICashierStore {
  isPaidPrepared: boolean;
  setPaidPrepared: (isPaidPrepared: boolean) => void;

  amountUsd: number;
  setAmountUsd: (addressFrom: number) => void;

  currentCurrency: Currency;
  setCurrentCurrency: (currentCurrency: Currency) => void;
}

const useCashierBase = create<ICashierStore>()(
  immer((set) => ({
    isPaidPrepared: false,
    setPaidPrepared: (isPaidPrepared) => set((store) => ({ ...store, isPaidPrepared })),

    amountUsd: 0,
    setAmountUsd: (amountUsd) => set((store) => ({ ...store, amountUsd })),

    currentCurrency: Currency.UsdtTrc20,
    setCurrentCurrency: (currentCurrency) => set((store) => ({ ...store, currentCurrency })),
  })),
);

export const useCashierStore = createSelectors(useCashierBase);
