import { useCallback, useEffect, useState } from 'react';

import { getMediaMatches } from 'lib/utils';

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(getMediaMatches(query));

  const handleChange = useCallback(() => {
    setMatches(getMediaMatches(query));
  }, [query]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [query, handleChange]);

  return matches;
};
