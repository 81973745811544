import { PropsWithChildren, useCallback } from 'react';
import { BottomSheetProps } from 'react-spring-bottom-sheet';

import { IModalProps } from 'lib/types';
import { useDialog } from 'lib/contexts';
import { useSiteConfigStore } from 'store';

import { Modal } from '../Modal';
import { BottomSheet } from '../BottomSheet';

type TBottomSheetProps = Pick<
  BottomSheetProps,
  'className' | 'scrollLocking' | 'hidden' | 'onDismiss' | 'header' | 'footer'
> & {
  isOpened?: boolean;
};

type TDialogVariant = 'bottomSheet' | 'modal';

interface IDialogProps {
  withCloseAll?: boolean;
  bottomSheetProps?: Omit<TBottomSheetProps, 'isOpened' | 'isHidden' | 'onClose'>;
  mobileAs?: TDialogVariant;
  modalProps?: Omit<IModalProps, 'isHidden' | 'onClose'>;
  tabletAs?: TDialogVariant;
  onClose?: () => void;
}

export const Dialog = ({
  withCloseAll,
  mobileAs = 'bottomSheet',
  tabletAs = 'modal',
  bottomSheetProps,
  children,
  modalProps,
  onClose,
}: PropsWithChildren<IDialogProps>) => {
  const isTablet = useSiteConfigStore.use.isTablet();
  const isMobile = useSiteConfigStore.use.isMobile();

  const { isActive, isHidden, closeDialog, closeDialogs } = useDialog();

  const handleClose = useCallback(() => {
    if (withCloseAll) {
      closeDialogs();
    } else {
      closeDialog();
    }

    onClose?.();
  }, [closeDialog, closeDialogs, onClose, withCloseAll]);

  if ((isMobile && mobileAs === 'bottomSheet') || (isTablet && tabletAs === 'bottomSheet')) {
    return (
      <BottomSheet {...bottomSheetProps} isOpened hidden={isHidden} onDismiss={handleClose}>
        {children}
      </BottomSheet>
    );
  }

  return (
    <Modal {...modalProps} isActive={isActive} isHidden={isHidden} onClose={handleClose}>
      {children}
    </Modal>
  );
};
