import { isNumeric } from 'lib/utils';
import { BREAKPOINTS } from 'lib/constants';

import { useMediaQuery } from './useMediaQuery';

type BreakPointsKey = keyof typeof BREAKPOINTS;

export const useMediaGreater = (k: BreakPointsKey | number) => (
  useMediaQuery(`(min-width: ${isNumeric(k) ? k : BREAKPOINTS[k] + 1}px)`)
);

export const useMediaSmaller = (k: BreakPointsKey | number) => (
  useMediaQuery(`(max-width: ${isNumeric(k) ? k : BREAKPOINTS[k]}px)`)
);

export const useMediaBetween = (a: BreakPointsKey | number, b: BreakPointsKey | number) => (
  useMediaQuery(
    `(min-width: ${isNumeric(a) ? a : BREAKPOINTS[a] + 1}px) and (max-width: ${isNumeric(b) ? b : BREAKPOINTS[b]}px)`,
  )
);
