import { Trans, useTranslation } from 'react-i18next';

import { useDepositCrypto } from 'api';
import { CURRENCIES } from 'lib/models';

import { Icon, Text, Timer, Title } from 'components';

import { useCashierStore } from '../../store';

import { InfoBox } from './InfoBox';
import { AmountToPay } from './AmountToPay';
import { WalletAddress } from './WalletAddress';
import { PaymentDetailsButtons } from './Buttons';

import st from './PaymentDetails.module.less';

interface IProps {
  className?: string;
}

export const PaymentDetails = ({ className }: IProps) => {
  const amountUsd = useCashierStore.use.amountUsd();
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { t } = useTranslation();

  const {
    data: {
      currency,
      fee = 0,
      serviceFee,
    } = {},
  } = useDepositCrypto({ amountUsd, currency: currentCurrency }, {
    enabled: false,
  });

  return (
    <div className={className}>
      <div className={st.header}>
        <Title size="h2">
          {t('cashier_page.payment_details.title')}
        </Title>

        <Timer />
      </div>

      <Text className={st.successText} mobileSize="s">
        {t('cashier_page.payment_details.success')}
      </Text>

      <div className={st.form}>
        {currency && (
          <InfoBox label={t('cashier_page.payment_details.network')}>
            <div className={st.currency}>
              <Icon icon={CURRENCIES[currency].icon} />
              {CURRENCIES[currency].text}
            </div>
          </InfoBox>
        )}

        <InfoBox label={t('cashier_page.payment_details.service_fee')}>
          {serviceFee}
        </InfoBox>

        <InfoBox
          label={t('cashier_page.payment_details.transfer_fee')}
          tooltip={t('cashier_page.payment_details.commission_tooltip')}
        >
          {fee}
        </InfoBox>

        <Text color="grayBlue" className={st.description} mobileSize="s">
          <Trans
            i18nKey="cashier_page.payment_details.instruction"
            components={{ span: <Text as="span" color="white" weight="w700" /> }}
          />
        </Text>

        <AmountToPay />
      </div>

      <WalletAddress />
      <PaymentDetailsButtons />
    </div>
  );
};
