import { useQuery } from 'react-query';

import { api } from 'lib/utils';
import { ExtractFnReturnType, QueryConfigWithSelect } from 'lib/query';

import { IDepositCryptoDTO, IDepositCryptoRequisitesDTO } from '../../contracts';

const fetchDepositCrypto = (data?: IDepositCryptoDTO): Promise<IDepositCryptoRequisitesDTO> => (
  api.post('/api/account/deposit/crypto', data)
);

type TQueryFn = typeof fetchDepositCrypto;

export const useDepositCrypto = <D = ExtractFnReturnType<TQueryFn>>(
  data?: IDepositCryptoDTO,
  config?: QueryConfigWithSelect<TQueryFn, D>,
) => useQuery<ExtractFnReturnType<TQueryFn>, unknown, D>({
  ...config,
  queryKey: ['deposit-crypto', data],
  queryFn: () => fetchDepositCrypto(data),
});
