import { useCallback, useMemo } from 'react';

import { IButtonProps } from '../Button';

import { IPagination } from './types';
import { MAX_ITEMS_COUNT } from './config';

interface IUsePagination extends IPagination {
  btnProps?: IButtonProps;
  onClick: (page: number) => void;
}

export const usePagination = ({ btnProps, limit, offset, total: paginationTotal, onClick }: IUsePagination) => {
  const baseBtnProps: IButtonProps = useMemo(
    () => ({
      theme: 'secondary',
      ...btnProps,
    }),
    [btnProps],
  );

  const pages = useMemo(() => {
    const total = Math.ceil(Math.min(paginationTotal, MAX_ITEMS_COUNT - limit) / limit);
    const current = Math.ceil(offset / limit) + 1;
    const delta = 2;

    if (delta * 2 + 5 >= total) {
      const result = [];

      for (let count = 1; count <= total; count++) {
        result[count] = count;
      }

      return result;
    }

    const res: Array<string | number> = [];
    const len = 2 * delta + 3;

    let currentIndex;
    let i;
    let vars;

    if (current < len - (delta + 1)) {
      currentIndex = current;
    } else if (total - current <= delta + 1) {
      currentIndex = len - (total - current);
    } else {
      currentIndex = delta + 2;
    }

    res[1] = 1;
    res[len] = total;
    res[currentIndex] = current;

    for (i = currentIndex + 1, vars = current + 1; i < len; i++, vars++) {
      res[i] = i !== len - 1 || vars === total - 1 ? vars : '...';
    }

    for (i = currentIndex - 1, vars = current - 1; i > 1; i--, vars--) {
      res[i] = i !== 2 || vars === 2 ? vars : '...';
    }

    return res.splice(1);
  }, [limit, offset, paginationTotal]);

  const handleClick = useCallback(
    (page: number) => () => {
      onClick?.(page);
    },
    [onClick],
  );

  return {
    baseBtnProps,
    current: Math.ceil(offset / limit) + 1,
    pages,
    total: Math.ceil(Math.min(paginationTotal, MAX_ITEMS_COUNT - limit) / limit),
    handleClick,
  };
};
