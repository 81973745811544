import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { iconInfo } from 'assets';
import { useDialogsAdd } from 'lib/contexts';

import { PseudoLink, RulesDialog } from 'components';

interface IProps {
  className?: string;
}

export const ButtonRulesShow = ({ className }: IProps) => {
  const addDialog = useDialogsAdd();

  const { t } = useTranslation();

  const handleClick = useCallback(() => addDialog(<RulesDialog />), [addDialog]);

  return (
    <PseudoLink className={className} icon={iconInfo} mobileSize="xs" weight="w500" onClick={handleClick}>
      {t('game.rules')}
    </PseudoLink>
  );
};
