import { useCallback, useState } from 'react';
import useOnclickOutside, { Return } from 'react-cool-onclickoutside';

import { noop } from 'lib/utils';

import { useOnEscapePress } from './useOnEscapePress';
import { useLockBodyScroll } from './useLockBodyScroll';

export interface IUseToggleOpenProps {
  initial?: boolean;
  lockBody?: boolean;
  onToggle?: (val: boolean) => void;
}

type TUseToggleOpenReturn = {
  isOpen: boolean;
  ref: Return;
  toggleOpen: () => void;
  close: () => void;
};

export const useToggleOpen = ({
  onToggle,
  initial = false,
  lockBody = true,
}: IUseToggleOpenProps | undefined = {}): TUseToggleOpenReturn => {
  const [isOpen, setOpen] = useState(initial);

  useLockBodyScroll(lockBody && isOpen);

  const close = useCallback(() => {
    setOpen(false);
    onToggle?.(false);
  }, [onToggle]);

  const handleSetOpen = useCallback(() => {
    setOpen(!isOpen);
    onToggle?.(!isOpen);
  }, [isOpen, onToggle]);

  useOnEscapePress(isOpen ? close : noop);

  const ref = useOnclickOutside(close, { disabled: !isOpen });

  return { isOpen, ref, toggleOpen: handleSetOpen, close };
};
