import { createElement, PropsWithChildren } from 'react';
import cn from 'classnames';

import { ITextProps } from 'lib/types';
import { useSiteConfigStore } from 'store';

import st from './Text.module.less';

export const Text = ({
  rubik,
  uppercase,
  as = 'p',
  className,
  children,
  color = '',
  size = '',
  mobileSize = '',
  weight = '',
  ...rest
}: PropsWithChildren<ITextProps>) => {
  const isDesktop = useSiteConfigStore.use.isDesktop();

  const currentSize = isDesktop ? size : mobileSize || size;

  return createElement(
    as,
    {
      className: cn(
        {
          [st.rubik]: rubik,
          [st[color]]: color,
          [st[currentSize]]: currentSize,
          [st[weight]]: weight,
          [st.uppercase]: uppercase,
        },
        className,
      ),
      ...rest,
    },
    children,
  );
};
