import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChangePasswordStore } from 'store';
import { useDialogClose, useDialogsAdd } from 'lib/contexts';
import { PASSPHRASE_ERROR_MESSAGE, VALID_PASSPHRASE_LENGTH } from 'lib/constants';

import { Text, Input, Button, ChangePasswordDialog, LoginDialog } from 'components';

import { Dialog } from '../Dialog';

import st from './RecoveryDialog.module.less';

export const RecoveryDialog = () => {
  const passphrase = useChangePasswordStore.use.passphrase();

  const { setPassphrase } = useChangePasswordStore.getState();

  const { t } = useTranslation();

  const [error, setError] = useState('');

  const addDialog = useDialogsAdd();
  const closeDialog = useDialogClose();

  const handleChange = useCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    setPassphrase(value);
  }, [setPassphrase]);

  const handleContinueClick = useCallback(() => {
    if (passphrase.length < VALID_PASSPHRASE_LENGTH) {
      setError(PASSPHRASE_ERROR_MESSAGE);
      return false;
    }

    closeDialog();
    addDialog(<ChangePasswordDialog />);
  }, [addDialog, closeDialog, passphrase.length]);

  const handleCancelClick = useCallback(() => {
    closeDialog();
    addDialog(<LoginDialog />);
  }, [addDialog, closeDialog]);

  const handleClose = useCallback(() => addDialog(<LoginDialog />), [addDialog]);

  return (
    <Dialog onClose={handleClose}>
      <Text className={st.title} size="xl" weight="w600">
        {t('password_recovery_page.title')}
      </Text>

      <form className={st.form}>
        <Input
          modal
          errorMessage={error}
          autoComplete="off"
          name="passphrase"
          placeholder={t('registration_page.secret_word')}
          value={passphrase}
          onChange={handleChange}
        />
      </form>

      <div className={st.buttons}>
        <Button fluid disabled={!passphrase} onClick={handleContinueClick}>
          {t('continue')}
        </Button>

        <Button fluid theme="secondaryModal" onClick={handleCancelClick}>
          {t('cancel')}
        </Button>
      </div>
    </Dialog>
  );
};
