import { FC } from 'react';
import cn from 'classnames';

import { TSVG } from 'lib/types';

import st from './Icons.module.less';

export interface IIcon {
  className?: string;
  icon: TSVG;
  size?: number;
  shapeRendering?: string;
}

export const Icon: FC<IIcon> = ({
  className,
  icon: { id, viewBox } = {},
  size = 24,
  shapeRendering,
}) => (
  <svg
    className={cn(st.icon, className)}
    viewBox={viewBox}
    role="img"
    fontSize={size}
    {...shapeRendering && { shapeRendering }}
  >
    <use xlinkHref={`#${id}`} />
  </svg>
);
