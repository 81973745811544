import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ITextProps } from 'lib/types';

import { Text } from 'components';

import st from './CopiedSuccess.module.less';

interface IProps extends ITextProps {
  isShow?: boolean;
  className?: string;
}

export const CopiedSuccess = ({ isShow, className, children, ...textProps }: PropsWithChildren<IProps>) => {
  const { t } = useTranslation();

  return (
    <Text
      className={cn(st.copiedSuccess, { [st.shown]: isShow }, className)}
      weight="w500"
      {...textProps}
    >
      {children || t('copy_success')}
    </Text>
  );
};
