import { ReactNode, SyntheticEvent } from 'react';
import cn from 'classnames';

import { Text } from 'components';

import st from './Input.module.less';

export interface IInputProps {
  autoComplete?: string;
  disabled?: boolean;
  error?: boolean;
  modal?: boolean;
  readOnly?: boolean;
  className?: string;
  containerClassName?: string;
  errorMessage?: ReactNode;
  id?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
}

export const Input = ({
  autoComplete,
  disabled,
  error: isError,
  modal,
  readOnly,
  className,
  containerClassName,
  errorMessage,
  id,
  name,
  placeholder,
  type = 'text',
  value,
  onChange,
  onBlur,
}: IInputProps) => (
  <div className={containerClassName}>
    <input
      autoComplete={autoComplete}
      className={cn(st.input, { [st.error]: isError || errorMessage, [st.modal]: modal }, className)}
      disabled={disabled}
      id={id || name}
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      onBlur={onBlur}
    />

    {errorMessage && (
      <Text className={st.errorMessage} color="red" weight="w500" size="s">
        {errorMessage}
      </Text>
    )}
  </div>
);
