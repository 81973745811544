import { ComponentPropsWithoutRef } from 'react';
import cn from 'classnames';

import st from './Overlay.module.less';

export const Overlay = ({ className, children, ...restProps }: ComponentPropsWithoutRef<'aside'>) => (
  <aside {...restProps} className={cn(st.overlay, className)}>
    {children}
  </aside>
);
