import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useDepositCrypto } from 'api';

import { Button, ButtonPaid } from 'components';

import { useCashierStore } from '../../../store';

import st from './PaymentDetainsButtons.module.less';

interface IProps {
  className?: string;
}

export const PaymentDetailsButtons = ({ className }: IProps) => {
  const amountUsd = useCashierStore.use.amountUsd();
  const currentCurrency = useCashierStore.use.currentCurrency();

  const { setPaidPrepared } = useCashierStore.getState();

  const { t } = useTranslation();

  const { remove } = useDepositCrypto({ amountUsd, currency: currentCurrency }, {
    enabled: false,
  });

  const handleBackClick = useCallback(() => {
    remove();
    setPaidPrepared(false);
  }, [remove, setPaidPrepared]);

  useEffect(() => () => {
    remove();
    setPaidPrepared(false);
  }, [remove, setPaidPrepared]);

  return (
    <div className={cn(st.paymentDetainsButtons, className)}>
      <Button fluid theme="secondary" onClick={handleBackClick}>
        {t('back')}
      </Button>

      <ButtonPaid />
    </div>
  );
};
