import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (lockScroll?: boolean): void => useLayoutEffect(() => {
  const {
    body: { classList },
  } = document;

  classList.toggle('body-lock', !!lockScroll);

  return () => classList.remove('body-lock');
}, [lockScroll]);
