import { SyntheticEvent, useEffect, useState } from 'react';

import { useConversionRates } from 'api';
import { handleFieldChange } from 'lib/common';
import { MIN_WITHDRAW_AMOUNT } from 'lib/constants';

const INITIAL_VALUES = {
  amountUsd: 0,
  addressTo: '',
};

export const useWithdraw = () => {
  const [isLoadingConversionRates, setLoadingConversionRates] = useState(false);
  const [isLoadingWithdrawCrypto, setLoadingWithdrawCrypto] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [{ amountUsd, addressTo }, setValues] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState(INITIAL_VALUES);

  const {
    isFetching: isFetchingConversionRates,
    data: { amounts, commissions } = {},
    refetch: refetchConversionRates,
  } = useConversionRates(amountUsd, {
    enabled: false,
  });

  useEffect(() => {
    if (amountUsd >= MIN_WITHDRAW_AMOUNT) {
      setLoadingConversionRates(true);
    }

    const timeOutRefetch = setTimeout(() => {
      if (amountUsd >= MIN_WITHDRAW_AMOUNT) {
        refetchConversionRates();
        setLoadingConversionRates(false);
      }
    }, 1000);

    return () => clearTimeout(timeOutRefetch);
  }, [amountUsd, refetchConversionRates]);

  useEffect(() => {
    const timeOutAnimation = setTimeout(() => {
      if (amountUsd && amountUsd < MIN_WITHDRAW_AMOUNT) {
        setAnimated(true);
        setTimeout(() => setAnimated(false), 3000);
      }
    }, 800);

    return () => clearTimeout(timeOutAnimation);
  }, [amountUsd]);

  const handleInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setErrors(INITIAL_VALUES);
    handleFieldChange(setValues)(event);
  };

  return {
    isConversionRatesLoading: isLoadingConversionRates || isFetchingConversionRates,
    isLoadingWithdrawCrypto,
    isSuccess,
    addressTo,
    amounts,
    amountUsd,
    animated,
    commissions,
    errors,
    setErrors,
    setSuccess,
    setLoadingWithdrawCrypto,
    handleInputChange,
  };
};
