import { PropsWithChildren } from 'react';
import cn from 'classnames';

import st from './MinAmount.module.less';

interface IProps {
  animated?: boolean;
  className?: string;
}

const MinAmount = ({ animated, className, children }: PropsWithChildren<IProps>) => (
  <span className={cn(st.minAmount, { [st.animated]: animated }, className)}>
    {children}
  </span>
);

export default MinAmount;
