import { ROUTES } from 'lib/constants';
import { useTranslation } from 'react-i18next';

import { Button, Text, Title, Wrap } from 'components';

import st from './Error404.module.less';

export const Error404 = () => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <div className={st.error404}>
        <Title className={st.title}>
          {t('error_404_page.title')}
        </Title>

        <Text weight="w500">
          {t('error_404_page.text')}
        </Text>

        <Button fluid className={st.btn} href={ROUTES.GAME}>
          {t('error_404_page.btn')}
        </Button>
      </div>
    </Wrap>
  );
};
