import { useCallback, useState } from 'react';

import { cancelTransaction, useAccountTransactionsExternalQuery } from 'api';
import { useLocation } from 'react-router-dom';
import { searchParams } from '../../../lib/utils';

export const useTransactions = () => {
  const { search } = useLocation();

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 30,
  });

  const [isCancelTransactionError, setCancelTransactionError] = useState(false);

  const { pageSize, page } = pagination;

  const {
    isLoading,
    data: {
      items = [],
      totalItems = 0,
    } = {},
    refetch,
  } = useAccountTransactionsExternalQuery(pagination);

  const handleCancelClick = useCallback((transactionId: string) => () => {
    cancelTransaction(transactionId)
      .then(() => refetch())
      .catch(() => setCancelTransactionError(true));
  }, [refetch]);

  const handleCancelErrorClick = useCallback(() => setCancelTransactionError(false), []);

  const handlePagination = useCallback((_page: number) => setPagination({
    ...pagination,
    page: _page + 1,
  }), [pagination]);

  return {
    isLoading,
    isPaymentSuccessful: typeof searchParams.get(search, 'payment-successful') === 'string',
    isPaymentFailed: typeof searchParams.get(search, 'payment-failed') === 'string',
    isCancelTransactionError,
    items,
    totalItems,
    page,
    pageSize,
    handleCancelClick,
    handleCancelErrorClick,
    handlePagination,
  };
};
