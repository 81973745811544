import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { BackLink } from '../BackLink';

import st from './InnerPage.module.less';

interface IProps {
  backLink?: string;
  className?: string;
  contentClassName?: string;
  onBackLinkClick?: () => void;
}

export const InnerPage = ({
  backLink,
  className,
  contentClassName,
  children,
  onBackLinkClick,
}: PropsWithChildren<IProps>) => (
  <div className={cn(st.innerPage, className)}>
    <BackLink className={st.backLink} {...onBackLinkClick ? { onClick: onBackLinkClick } : { backLink }} />

    <div className={cn(st.content, contentClassName)}>
      {children}
    </div>
  </div>
);
