import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { LOCK_TIME } from 'lib/constants';
import { Button } from 'components/ui';

import { Text } from '../Text';

import st from './ButtonPaid.module.less';

interface IProps {
  className?: string;
  onClick?: () => void;
}

export const ButtonPaid = ({ className, onClick }: IProps) => {
  const { t } = useTranslation();

  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  const [paid, setPaid] = useState(false);
  const [timer, setTimer] = useState(LOCK_TIME);

  const tick = useCallback(() => {
    if (timer > 0) {
      setTimer(timer - 1);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    intervalRef.current = setInterval(() => tick(), 1000);

    return () => clearInterval(intervalRef.current);
  }, [tick]);

  const handlePaidClick = useCallback(() => {
    setPaid(true);
    onClick?.();
  }, [onClick]);

  return (
    <div className={cn(st.buttonPaid, className)}>
      <Button fluid disabled={paid} onClick={handlePaidClick} />

      <span className={cn(st.progress, { [st.done]: timer === 0 })}>
        <span className={st.progressBar} style={{ width: `${(timer / LOCK_TIME) * 100}%` }} />

        <Text uppercase as="span" className={st.progressContent} size="s" weight="w800">
          {t(`cashier_page.payment_details.${paid ? 'checking_payment' : 'i_paid_btn'}`)}
        </Text>
      </span>
    </div>
  );
};
